import React, { useState } from 'react';
import './TimeFilterPopup.css'
import { HISTORICAL_FLAG, REALTIME_FLAG } from '../../../../../../constants/constants';

export default function TimeFilterPopup(props) {

  const [timeTypeSelected, setTimeTypeSelected] = useState(props.currentFilter);

  const visibleClass = "visible";

  const updateFilterType = (filterType) => {    
    setTimeTypeSelected(filterType);
    props.setTimeFilterAction(filterType); 
  }

  const isRealTimeSelected = () => {    
    return timeTypeSelected === REALTIME_FLAG ? visibleClass : "";
  };

  const isHistoricalSelected = () => {    
    return timeTypeSelected !== REALTIME_FLAG ? visibleClass : ""; 
  };

  return (
    <div className="time-filter-popup">      
      <span className="filter-label"> 
        <span className={"v-icon " + isRealTimeSelected()}/>
        <button className={"filter-button " + isRealTimeSelected()} onClick={() => updateFilterType(REALTIME_FLAG)}> RealTime </button>
      </span>
      <span className="filter-label">
        <span className={"v-icon " + isHistoricalSelected()}/>
        <button className={"filter-button " + isHistoricalSelected()} onClick={() => updateFilterType(HISTORICAL_FLAG)}> Historical </button>
      </span>
    </div>
  );
}