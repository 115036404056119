import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import SecondaryBar from './secondary-bar/SecondaryBar';
import IncidentAlertOverview from './incident-alert-overview/IncidentAlertOverview';
import Map from './map/Map';
import Timeline from './timeline/Timeline';
import { useGlobal } from "../../../services/globalStatesStore";
import { getTimezone, startTimezoneInterval, stopTimezoneInterval } from './../../../services/timezoneService';


function Dashboard() {
    const [, setSelectedTimeFilter] = useState(null);
    const [globalState] = useGlobal();

    const timeFilterSelected = selectedTimeFilter => event => setSelectedTimeFilter(selectedTimeFilter);
    const [timeData, setTimeData] = useState({
        timePeriod: undefined,
        timezoneData: undefined
    });

    function evaluateTimezone() {
        if (isMissingDataInGlobal()) {
            return;
        }
        const onSuccessCallback = (timezoneDataResponse) => {
            if (!timezoneDataResponse) return;
            setTimeData({
                timePeriod: globalState.currentTimestamp,
                timezoneData: timezoneDataResponse
            });
        };
        getTimezone(globalState.currentTimestamp, globalState.locationData, globalState.configuration.dstTransitionHour, onSuccessCallback);
    }

    function isMissingDataInGlobal() {
        return !globalState.currentTimestamp || !globalState.locationData || Object.keys(globalState.locationData.location).length === 0 || !globalState.configuration.dstTransitionHour;
    }

    useEffect(() => {
        evaluateTimezone();
    }, [globalState.currentTimestamp, globalState.locationData.location]);

    useEffect(() => {
        if (!globalState.locationData || Object.keys(globalState.locationData.location).length === 0) return;

        if (globalState.timeFilterRange === "RealTime"){
            startTimezoneInterval(evaluateTimezone);
        }
        else{
            stopTimezoneInterval();
        }
        return () => {
            stopTimezoneInterval();
        };
    }, [globalState.timeFilterRange, globalState.locationData.location]);

    return (
        <div className="dashboard bg-161A1E">
            <div className="horizontal-seperator bg-02060A first"></div>
            <SecondaryBar switchTimeFilter={timeFilterSelected} timeData={timeData}/>
            <div className="horizontal-seperator bg-02060A second"></div>
            <IncidentAlertOverview />
            <div className="horizontal-seperator bg-02060A third"></div>
            <Map />
            <div className="horizontal-seperator bg-02060A four"></div>
            <div className="vertical-seperator bg-02060A left"></div>
            <div className="vertical-seperator bg-02060A right"></div>
            {
                timeData.timePeriod && timeData.timezoneData &&
                <Timeline timePeriod={timeData.timePeriod} timezoneData={timeData.timezoneData} />
            }
        </div>
    );
}

export default Dashboard;


