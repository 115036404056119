import React, { useState } from 'react';
import './IncidentAlertPriorityFilter.css';
import IncidentAlertFilterButton from './incident-alert-filter-button/IncidentAlertFilterButton';
import priorityFilterList from '../../../../../models/priorityFilterList';

function IncidentAlertPriorityFilter(props) {

    const [selectedPriorityFilter, setSelectedPriorityFilter] = useState(0);

    function isSelected(selectedPriorityFilter, filterPriority) {        
        return selectedPriorityFilter === filterPriority;
    }

    function setPriorityFilter(priority) {
        setSelectedPriorityFilter(priority);
        props.updateSelectedPriority(priority);
    }

    return(
        <div className="incident-alert-priority-filter">
            <div className='priority-seperator'></div>
            {priorityFilterList.map(filter =>                 
                <IncidentAlertFilterButton 
                    key={filter.type}
                    selected={isSelected(selectedPriorityFilter, filter.priority)}
                    color={filter.color}
                    onClick={() => setPriorityFilter(filter.priority)}>
                </IncidentAlertFilterButton>
            )}                        
        </div>
    );
}

export default IncidentAlertPriorityFilter;