import React, { useState, useEffect } from 'react';
import './IncidentAlertList.css';
import IncidentAlertListItem from './incident-alert-list-item/IncidentAlertListItem';
import IncidentAlertPopup from './../incident-alert-popup/IncidentAlertPopup';

function IncidentAlertList(props) {
    const items = props.incidentAlerts;

    const [isOpenIncidentAlertPopup, setIsOpenIncidentAlertPopup] = useState(false);
    const [incidentIdToOpen, setIncidentIdToOpen] = useState();

    return (
        <div>
            <div className='incident-alert-list'>
                {items && items.map((item) =>
                    <IncidentAlertListItem
                        key={item.id}
                        incidentData={item}
                        setIncidentIdToOpen={setIncidentIdToOpen}
                        setIsOpenIncidentAlertPopup={setIsOpenIncidentAlertPopup}>
                    </IncidentAlertListItem>
                )}
            </div>
            <div id='incidentListAlertPopup' className='incident-alert-popup-container'>
                {isOpenIncidentAlertPopup &&
                    <IncidentAlertPopup setIsOpenIncidentAlertPopup={setIsOpenIncidentAlertPopup} incidentId={incidentIdToOpen} />
                }
            </div>
        </div>
    );
}

export default IncidentAlertList;