import React from 'react';
import './IncidentAlertCategoryButton.css';

function IncidentAlertCategoryButton(props) {
    
    const alertsCount = props.filterData.alertsCount;
    const isActive = alertsCount !== 0;
    const iconSrc = isActive ? props.filterData.activeIcon : props.filterData.passiveIcon;
    const titleColorClass = isActive ? 'c-CACED2' : 'c-7A7E82';
    const borderColorClass = isActive ? props.filterData.borderColorClass : 'br-7A7E82';
    const isVisible = isActive ? 'visible' : 'hidden';
    const isSelected = isActive ? props.selected : false;
    const isCursorActive = isActive ? 'cursor-active' : 'cursor-not-active';
    return (
        <div onClick={props.onClick} className={isSelected ? `incident-alert-category-button-selected ${props.className}` : `incident-alert-category-button ${props.className} ${borderColorClass} ${isCursorActive}`}>
            <div className='filter-icon'>
                <img src={iconSrc} alt=""/>
            </div> 
            <div className={`filter-title ${titleColorClass}`}>{props.filterData.title}</div>
            <div className={`alert-ellipse c-F2F6FA palette-bg-red ${isVisible}`}>
                <div className="alerts-count">{alertsCount}</div>
            </div>
        </div>
    );
}

export default IncidentAlertCategoryButton;