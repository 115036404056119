import { API } from "aws-amplify";
import config from './../config';

const apiName = config.apiGateway.NAME;
const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'OPTIONS, GET, POST'
};
// the Authorization header is added via Custom Request Headers in index.js
async function get(url) {
  return await API.get(apiName, url, {body: "", headers: headers})
    .then((json) => {
      if (process.env.NODE_ENV === 'development') {
        // console.log('JSON response: ', json);
      }
      return json;
    })
    .catch((error) => {
      console.error("GET "+ url +" returned error message");
      console.error(error);
    });
}

async function post(url, body) {
  return await API.post(apiName, url, {
    body: body,
    headers: headers,
  })
    .then((json) => {
      if (process.env.NODE_ENV === 'development') {
        // console.log('JSON response: ', json);
      }
      return json;
    })
    .catch((error) => {
      console.error("POST "+ url +" returned error message");
      console.error(error);
    });
}


export const getLocationFilter = async (callback) => {
  const result = await get('locationfilter');
  callback(result);
}

export const getUserProfile = async (callback) => {
  const result = await get('user/profile');
  callback(result);
}

export const userLogout = async (callback) => {
  const result = await get('user/logout');
  callback(result);
}

export const sensors = async (callback) => {
  const result = await get('sensors');
  callback(result);
}

export const getConfiguration = async (callback, body) => {
  const result = await post('getconfiguration', body);
  callback(result);
}

export const postPollVehicles = async (callback, body) => {
  const result = await post('pollvehicles', body);
  callback(result);
}

export const postPollTimeline = async (callback, body) => {
  const result = await post('polltimeline', body);
  callback(result);
}

export const postPollIncidents = async (callback, body) => {
  const result = await post('pollincidents', body);
  callback(result);
}

export const postWeather = async (callback, body) => {
  const result = await post('weather', body);
  callback(result);
}

export const postUtcOffset = async (body, callback) => {
  const result = await post('utcoffset', body);
  callback(result);
}