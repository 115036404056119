import React, { useEffect, useRef, useState } from "react";
import { useGlobal } from "../../../../services/globalStatesStore";
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { bearing, point, distance } from '@turf/turf';
import Switch from "react-switch";
import { vehiclesDataName } from './../../../../services/pollingService'
import { sensors } from './../../../../services/apiCalls'
import PubSub from 'pubsub-js'
import './Map.css';
import largeGreenSvg from './images/icon-car-large-green.svg' //folder must be here to work with Mapbox
import largeOrangeSvg from './images/icon-car-large-orange.svg' //folder must be here to work with Mapbox
import largeRedSvg from './images/icon-car-large-red.svg' //folder must be here to work with Mapbox

var vehiclesSourceData = {
    type: "FeatureCollection",
    features: []
};
var trailsGreenSourceData = {
    type: "FeatureCollection",
    features: []
};
var trailsOrangeSourceData = {
    type: "FeatureCollection",
    features: []
};
var trailsRedSourceData = {
    type: "FeatureCollection",
    features: []
};

var pauseFlag;

export const setPauseFlag = (flag) => {
    pauseFlag = flag;
}

export const initBufferedVehicles = () => {
    vehiclesSourceData.features = [];
    trailsGreenSourceData.features = [];
    trailsOrangeSourceData.features = [];
    trailsRedSourceData.features = [];
}

function Map() {
    const [globalState, ] = useGlobal();
    const mapboxToken = 'pk.eyJ1IjoiY29udHJvbGNlbnRlcmRldiIsImEiOiJjazN6eTI5NG8wZGhxM3JtbTk0eTJxYWk2In0.ADLSHRTR3VwDWh48grYNFQ';
    const [map, setMap] = useState(null);
    const [showTrails, setShowTrails] = useState(true);
    const mapContainer = useRef(null);
    const pointsInSecond = 60;
    const numberOfSecondsToShowTrail = 2;
    const vehiclesSourceName = "vehiclesSource";
    const sensorsSourceName = "sensorsSource";
    const vehiclesGreenTrailsSourceName = "vehiclesGreenTrailsSource";
    const vehiclesOrangeTrailsSourceName = "vehiclesOrangeTrailsSource";
    const vehiclesRedTrailsSourceName = "vehiclesRedTrailsSource";
    const roadSourceName = "roadSource"
    const vehiclesImageSize = 13;
    const smallVehicleMultiplierComparedToLane = 0.4;
    const largeVehicleMultiplierComparedToLane = 0.7;
    const pixelsToSubtractFromVehicleSizeToPresentTrail = 4;
    const sensorsColor = '#989CA0';
    const roadLensColor = '#2A2E32';
    var laneSizeInPixels = 50;
    var numberOfSecondsInInterval = 1;
    var totalPointsInInterval = pointsInSecond * numberOfSecondsInInterval;

    useEffect(() => {
        let subscribeReferece = null;
        const sensorsLayer = {
            "id": "sensors",
            "source": sensorsSourceName,
            "type": "circle",
            'paint': {
                // make circles larger as the user zooms from z12 to z22
                'circle-radius': {
                    'base': 1.75,
                    'stops': [
                        [12, 2],
                        [22, 10]
                    ]
                },
                'circle-color': sensorsColor
            }
        };

        const vehiclesLayer = {
            "id": "vehicles",
            "source": vehiclesSourceName,
            "type": "symbol",
            "layout": {
                "icon-image": {
                    "property": "icon_type",
                    "type": "identity"
                },
                "icon-size": {
                    "property": "icon_size",
                    "type": "identity"
                },
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "text-field": "{speed}km/h",
                "text-font": [
                    'Open Sans Bold',
                    'Arial Unicode MS Bold'
                ],
                "text-size": 11,
                "text-offset": [0, 1.1],
                "icon-rotate": {
                    "property": "icon_rotate",
                    "type": "identity"
                }
            },
            "paint": {
                'text-color': '#FFFFFF',
                'text-halo-color': '#000000',
                'text-halo-width': 2
            },
        };

        const vehiclesGreenTrailsLayer = {
            "id": "vehicles-green-trial",
            "source": vehiclesGreenTrailsSourceName,
            "type": "line",
            "layout": {},
            "paint": {
                "line-width": { "property": "line_width", "type": "identity" },
                "line-opacity": 1,
                "line-gradient": [
                    "interpolate", ["linear"],
                    ["line-progress"],
                    0, "rgba(0, 140, 70, 0.1)",
                    1, "rgba(0, 140, 70, 0.8)"
                ],
            }
        };

        const vehiclesOrangeTrailsLayer = {
            "id": "vehicles-orange-trial",
            "source": vehiclesOrangeTrailsSourceName,
            "type": "line",
            "layout": {},
            "paint": {
                "line-width": { "property": "line_width", "type": "identity" },
                "line-opacity": 1,
                "line-gradient": [
                    "interpolate", ["linear"],
                    ["line-progress"],
                    0, "rgba(255, 165, 0, 0.1)",
                    1, "rgba(255, 165, 0, 0.8)"
                ],
            }
        };

        const vehiclesRedTrailsLayer = {
            "id": "vehicles-red-trial",
            "source": vehiclesRedTrailsSourceName,
            "type": "line",
            "layout": {},
            "paint": {
                "line-width": { "property": "line_width", "type": "identity" },
                "line-opacity": 1,
                "line-gradient": [
                    "interpolate", ["linear"],
                    ["line-progress"],
                    0, "rgba(255, 0, 0, 0.1)",
                    1, "rgba(255, 0, 0, 0.8)"
                ],
            }
        };

        const roadLayer = {
            "id": "road",
            "source": roadSourceName,
            "type": "fill",
            "layout": {},
            "paint": {
                "fill-color": roadLensColor,
                "fill-opacity": 0.5
            }
        };

        mapboxgl.accessToken = mapboxToken;
        const initializeMap = ({
            setMap,
            mapContainer
        }) => {
            var map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/controlcenterdev/ck417s31y02gd1dpiwdjzrp6v?optimize=true',
                interactive: false
            });

            // addLocationViewForDebugging(map);

            var icontypes = {
                "0": "large-green",
                "1": "large-orange",
                "2": "large-red",
            };

            var sensorsSourceData = {
                type: "FeatureCollection",
                features: []
            };
            var roadSourceData = {
                "type": "FeatureCollection",
                features: []
            };

            mapContainer.current.showVehiclesTrails = true;
            loadVehicleImages(map);
            map.on('style.load', () => {
                initRoadWidth(map);
                sensors((sensors) => {
                    focusOnSensorsBounds(sensors);
                    initSensorsSource(sensors);
                    initRoadSource(sensors)
                    map.getSource(sensorsSourceName).setData(sensorsSourceData);
                    map.getSource(roadSourceName).setData(roadSourceData);
                });
                calcTotalPoints();
                subscribeToDataPoll();
                initSourcesAndLayers();
                initMap();
                if (process.env.NODE_ENV === 'development') {
                    sensorsIdLayerForDebugging();
                }
            });
            // Pans, rotates and zooms the map to to fit the box made by the sensors geo data(road segment)  
            function focusOnSensorsBounds(sensors) {
                let bestBearing = 0;
                let lanes = [];
                sensors && sensors.forEach((sensor => {
                    if (!lanes[sensor.line - 1]) {
                        lanes[sensor.line - 1] = [];
                    }
                    lanes[sensor.line - 1].push([sensor.location.longitude, sensor.location.latitude]);
                }));
                const bounds = findMapBoundsBySensors(sensors);
                const lastIndex = lanes[0].length - 1;
                const firstSensorCords = [sensors[0].location.longitude, sensors[0].location.latitude];
                const lastSensorCords = [sensors[lastIndex].location.longitude, sensors[lastIndex].location.latitude];
                let bearingBetweenSensors = bearing(point(firstSensorCords), point(lastSensorCords));
                if (bearingBetweenSensors < 0) bearingBetweenSensors += 360;

                let rotateRoadltrBearing = 0;

                if (firstSensorCords < lastSensorCords ||
                    firstSensorCords[0] < 0 || firstSensorCords[1] < 0) {
                    rotateRoadltrBearing = 180;
                }
                bestBearing = 90 + bearingBetweenSensors - rotateRoadltrBearing;
                map.setBearing(bestBearing);

                fitBoundsRotated(bounds, {
                    padding: 0,
                    duration: 0
                });

                let screenBound = findScreenBoundsBySensors(sensors);
                let i = 0
                let h = map.getCanvas().height;
                let w = map.getCanvas().width;
                const finalRoadPadding = 20;
                while (i < 1000) {
                    zoomInOnRotatedRoad(-i)
                    screenBound = findScreenBoundsBySensors(sensors);
                    if (screenBound[0][0] < finalRoadPadding ||
                        screenBound[0][1] < finalRoadPadding ||
                        h - screenBound[1][0] < finalRoadPadding ||
                        w - screenBound[1][1] < finalRoadPadding) {
                        break;
                    }
                    i += 10;
                }

                function zoomInOnRotatedRoad(padding) {
                    fitBoundsRotated(bounds, {
                        padding: padding,
                        duration: 0
                    });
                }

                screenBound = findScreenBoundsBySensors(sensors);
            }

            function findScreenBoundsBySensors(sensors) {
                if (!sensors || !sensors[0]) {
                    console.warn("No sensors data");
                    return [
                        [0, 0],
                        [0, 0]
                    ];
                }
                let minLatitude = map.project([sensors[0].location.longitude, sensors[0].location.latitude]).x,
                    maxLatitude = map.project([sensors[0].location.longitude, sensors[0].location.latitude]).x;
                let minLongitude = map.project([sensors[0].location.longitude, sensors[0].location.latitude]).y,
                    maxLongitude = map.project([sensors[0].location.longitude, sensors[0].location.latitude]).y;
                for (let i = 1, len = sensors.length; i < len; i++) {
                    let currentLat = map.project([sensors[i].location.longitude, sensors[i].location.latitude]).x;
                    let currentLon = map.project([sensors[i].location.longitude, sensors[i].location.latitude]).y;
                    minLatitude = (currentLat < minLatitude) ? currentLat : minLatitude;
                    maxLatitude = (currentLat > maxLatitude) ? currentLat : maxLatitude;
                    minLongitude = (currentLon < minLongitude) ? currentLon : minLongitude;
                    maxLongitude = (currentLon > maxLongitude) ? currentLon : maxLongitude;
                }
                return [
                    [minLongitude, minLatitude],
                    [maxLongitude, maxLatitude]
                ];
            }

            function findMapBoundsBySensors(sensors) {
                if (!sensors || !sensors[0]) {
                    console.warn("No sensors data");
                    return [
                        [0, 0],
                        [0, 0]
                    ];
                }
                let minLatitude = sensors[0].location.latitude,
                    maxLatitude = sensors[0].location.latitude;
                let minLongitude = sensors[0].location.longitude,
                    maxLongitude = sensors[0].location.longitude;
                for (let i = 1, len = sensors.length; i < len; i++) {
                    let currentLat = sensors[i].location.latitude;
                    let currentLon = sensors[i].location.longitude;
                    minLatitude = (currentLat < minLatitude) ? currentLat : minLatitude;
                    maxLatitude = (currentLat > maxLatitude) ? currentLat : maxLatitude;
                    minLongitude = (currentLon < minLongitude) ? currentLon : minLongitude;
                    maxLongitude = (currentLon > maxLongitude) ? currentLon : maxLongitude;
                }
                return [
                    [minLongitude, minLatitude],
                    [maxLongitude, maxLatitude]
                ];
            }

            function fitBoundsRotated(bounds, options, eventData) {
                if (!options) {
                    options = {};
                }
                if (!options.padding) {
                    options.padding = {
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0
                    };
                }
                if (!options.offset) {
                    options.offset = [0, 0];
                }
                if (!options.maxZoom) {
                    options.maxZoom = map.transform.maxZoom;
                }

                if (typeof options.padding === 'number') {
                    const p = options.padding;
                    options.padding = {
                        top: p,
                        bottom: p,
                        right: p,
                        left: p
                    };
                }
                bounds = mapboxgl.LngLatBounds.convert(bounds);
                const paddingOffset = [options.padding.left - options.padding.right, options.padding.top - options.padding.bottom],
                    lateralPadding = Math.min(options.padding.right, options.padding.left),
                    verticalPadding = Math.min(options.padding.top, options.padding.bottom);
                options.offset = [options.offset[0] + paddingOffset[0], options.offset[1] + paddingOffset[1]];

                options.bearing = options.bearing || map.getBearing();

                const offset = mapboxgl.Point.convert(options.offset),
                    tr = map.transform,
                    nw = tr.project(bounds.getNorthWest()),
                    se = tr.project(bounds.getSouthEast()),
                    size = se.sub(nw);
                const theta = options.bearing * (Math.PI / 180),
                    W = size.x * Math.abs(Math.cos(theta)) + size.y * Math.abs(Math.sin(theta)),
                    H = size.x * Math.abs(Math.sin(theta)) + size.y * Math.abs(Math.cos(theta)),
                    rotatedSize = {
                        x: W,
                        y: H
                    },

                    scaleX = (tr.width - lateralPadding * 2 - Math.abs(offset.x) * 2) / rotatedSize.x,
                    scaleY = (tr.height - verticalPadding * 2 - Math.abs(offset.y) * 2) / rotatedSize.y;

                if (scaleY < 0 || scaleX < 0) {
                    if (typeof console !== "undefined") console.warn('Map cannot fit within canvas with the given bounds, padding, and/or offset.');
                    return map;
                }

                options.center = tr.unproject(nw.add(se).div(2));
                options.zoom = Math.min(tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)), options.maxZoom);
                return options.linear ? map.easeTo(options, eventData) : map.flyTo(options, eventData);
            }

            function initSensorsSource(sensors) {
                sensors && sensors.forEach(sensor => {
                    sensorsSourceData.features.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [sensor.location.longitude, sensor.location.latitude]
                        },
                        "properties": {
                            "id": sensor.id,
                            "uuid": sensor.uuid,
                            "line": sensor.line
                        }
                    });
                });
            }

            function initRoadSource(sensors) {
                let lanePolygons = createLanePolygons();
                lanePolygons.forEach((lane) => {
                    roadSourceData.features.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Polygon",
                            "coordinates": [lane]
                        }
                    });
                });

                function createLanePolygons() {
                    let lanesArray = [];
                    let sensorsByLineArray = [];
                    //the assumption is that the sensors are ordered inside a line
                    sensors && sensors.forEach((sensor => {
                        if (!sensorsByLineArray[sensor.line - 1]) {
                            sensorsByLineArray[sensor.line - 1] = [];
                        }
                        sensorsByLineArray[sensor.line - 1].push([sensor.location.longitude, sensor.location.latitude]);
                    }));
                    for (let i = 0; i < sensorsByLineArray.length - 1; i++) {
                        lanesArray.push([JSON.parse(JSON.stringify(sensorsByLineArray[i])).reverse(), sensorsByLineArray[i + 1]].flat());
                    }
                    calcLaneSizeInPixels(map, sensorsByLineArray);

                    return lanesArray;
                }
                return lanePolygons;
            }

            function subscribeToDataPoll() {
                subscribeReferece = PubSub.subscribe(vehiclesDataName, function(msg, vehicles) {
                    if (typeof vehicles === 'undefined') {
                        console.error("Error: No data from server");
                        vehicles = [];
                    }
                    initVehiclesSource(vehicles);
                    initTrailsSourceByDeepCloningVehicles();
                });
            }

            function initVehiclesSource(vehicles) {
                vehiclesSourceData.type = "FeatureCollection";
                vehiclesSourceData.features.length = 0;

                if (!vehicles || !vehicles.length) {
                    return;
                }

                vehicles.forEach(vehicle => {
                    if (vehicle.movementPoints.length < 2) {
                        console.error("Error: Not enough points for vehicles with id:" + vehicle.id);
                        return;
                    }
                    vehicle.movementPoints = generateMorePointsForAnimation(vehicle.movementPoints);
                    vehiclesSourceData.features.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [vehicle.movementPoints[0].geoLocation.longitude, vehicle.movementPoints[0].geoLocation.latitude]
                        },
                        "properties": {
                            "id": vehicle.id,
                            "color": vehicle.color,
                            "speed": vehicle.speed,
                            "size": vehicle.size,
                            "movementPoints": vehicle.movementPoints,
                            "icon_type": "large-green",
                            "icon_rotate": bearing(
                                point([vehicle.movementPoints[0].geoLocation.longitude, vehicle.movementPoints[0].geoLocation.latitude]),
                                point([vehicle.movementPoints[1].geoLocation.longitude, vehicle.movementPoints[1].geoLocation.latitude])
                            ) - map.getBearing(),
                            "line_width": 10,
                            "icon_size": 1

                        }
                    });
                });
            }


            function initTrailsSourceByDeepCloningVehicles() {
                let allOldTrails = saveOldTrails();

                trailsGreenSourceData.features.length = 0;
                trailsOrangeSourceData.features.length = 0;
                trailsRedSourceData.features.length = 0;

                vehiclesSourceData.features.forEach((feature) => {
                    let trailFeature = JSON.parse(JSON.stringify(feature));
                    trailFeature.type = "Feature";
                    trailFeature.geometry.type = "LineString";
                    trailFeature.geometry.coordinates = [];
                    trailFeature.properties.pointsTimestamp = [];
                    determineColorAndIconType(feature, trailFeature);
                    connectToOldTrailCords(trailFeature, allOldTrails);
                });

                function saveOldTrails() {
                    let temp = [];
                    Array.prototype.push.apply(temp, trailsGreenSourceData.features);
                    Array.prototype.push.apply(temp, trailsOrangeSourceData.features);
                    Array.prototype.push.apply(temp, trailsRedSourceData.features);
                    return temp;
                }

                function connectToOldTrailCords(trailFeature, allOldTrails) {
                    allOldTrails.forEach(element => {
                        if (element.properties.id === trailFeature.properties.id) {
                            trailFeature.geometry.coordinates.length = 0;
                            trailFeature.geometry.coordinates = element.geometry.coordinates;
                            trailFeature.properties.pointsTimestamp = element.properties.pointsTimestamp;
                            Array.prototype.unshift.apply(trailFeature.properties.movementPoints, element.properties.movementPoints.slice(-element.properties.pointsTimestamp.length));
                            return;
                        }
                    });
                }

                function determineColorAndIconType(vehicleFeature, trailFeature) {
                    let optimizedVehicleIndex = vehicleFeature.properties.color * 10 + vehicleFeature.properties.size;
                    const smallVehicleSize = laneSizeInPixels / vehiclesImageSize * smallVehicleMultiplierComparedToLane;
                    const largeVehicleSize = laneSizeInPixels / vehiclesImageSize * largeVehicleMultiplierComparedToLane;
                    const smallTrailWidth = laneSizeInPixels * smallVehicleMultiplierComparedToLane - pixelsToSubtractFromVehicleSizeToPresentTrail;
                    const largeTrailWidth = laneSizeInPixels * largeVehicleMultiplierComparedToLane - pixelsToSubtractFromVehicleSizeToPresentTrail;
                    switch (optimizedVehicleIndex) {
                        case 0:
                            setColorAndIconType(smallVehicleSize, smallTrailWidth, 0, trailsGreenSourceData);
                            break;
                        case 1:
                            setColorAndIconType(largeVehicleSize, largeTrailWidth, 0, trailsGreenSourceData);
                            break;
                        case 10:
                            setColorAndIconType(smallVehicleSize, smallTrailWidth, 1, trailsOrangeSourceData);
                            break;
                        case 11:
                            setColorAndIconType(largeVehicleSize, largeTrailWidth, 1, trailsOrangeSourceData);
                            break;
                        case 20:
                            setColorAndIconType(smallVehicleSize, smallTrailWidth, 2, trailsRedSourceData);
                            break;
                        case 21:
                            setColorAndIconType(largeVehicleSize, largeTrailWidth, 2, trailsRedSourceData);
                            break;
                        default:
                            console.error("Invalid vehicle color or size from server");
                            trailsRedSourceData.features.push(trailFeature);
                    };

                    function setColorAndIconType(iconSize, tailWidth, iconIndex, trailSourceData) {
                        trailFeature.properties.line_width = tailWidth;
                        vehicleFeature.properties.icon_type = icontypes[iconIndex];
                        vehicleFeature.properties.icon_size = iconSize;
                        trailSourceData.features.push(trailFeature);
                    }
                }
            }

            function initSourcesAndLayers() {
                map.addSource(roadSourceName, {
                    "type": "geojson",
                    "data": roadSourceData,
                });
                map.addSource(sensorsSourceName, {
                    "type": "geojson",
                    "data": sensorsSourceData
                });
                map.addSource(vehiclesSourceName, {
                    "type": "geojson",
                    "data": vehiclesSourceData
                });
                map.addSource(vehiclesGreenTrailsSourceName, {
                    "type": "geojson",
                    "data": trailsGreenSourceData,
                    "lineMetrics": true,
                });
                map.addSource(vehiclesOrangeTrailsSourceName, {
                    "type": "geojson",
                    "data": trailsOrangeSourceData,
                    "lineMetrics": true,
                });
                map.addSource(vehiclesRedTrailsSourceName, {
                    "type": "geojson",
                    "data": trailsRedSourceData,
                    "lineMetrics": true,
                });
                map.addControl(new mapboxgl.ScaleControl({
                    unit: 'metric'
                }), 'bottom-right');

                map.addControl(new mapboxgl.NavigationControl());
                
                map.addLayer(roadLayer);
                map.addLayer(sensorsLayer);
                map.addLayer(vehiclesGreenTrailsLayer);
                map.addLayer(vehiclesRedTrailsLayer);
                map.addLayer(vehiclesOrangeTrailsLayer);
                map.addLayer(vehiclesLayer);
            }

            function initMap() {
                function animateMarker(timestamp) {
                    newPosition(timestamp);
                    map.getSource(vehiclesSourceName).setData(vehiclesSourceData);
                    map.getSource(vehiclesGreenTrailsSourceName).setData(trailsGreenSourceData);
                    map.getSource(vehiclesOrangeTrailsSourceName).setData(trailsOrangeSourceData);
                    map.getSource(vehiclesRedTrailsSourceName).setData(trailsRedSourceData);

                    shouldShowTrails();
                    mapContainer.current.animationFrameRef = requestAnimationFrame(animateMarker);

                    function shouldShowTrails() {
                        if (mapContainer.current && mapContainer.current.showVehiclesTrails) {
                            map.setLayoutProperty("vehicles-green-trial", 'visibility', 'visible');
                            map.setLayoutProperty("vehicles-orange-trial", 'visibility', 'visible');
                            map.setLayoutProperty("vehicles-red-trial", 'visibility', 'visible');
                        } else {
                            map.setLayoutProperty("vehicles-green-trial", 'visibility', 'none');
                            map.setLayoutProperty("vehicles-orange-trial", 'visibility', 'none');
                            map.setLayoutProperty("vehicles-red-trial", 'visibility', 'none');
                        }
                    }
                }
                // Start the animation.
                animateMarker(0);
            }

            function sensorsIdLayerForDebugging() {
                map.addLayer({
                    id: "sensors-id",
                    type: "symbol",
                    source: sensorsSourceName,
                    layout: {
                        "text-field": "{id}",
                        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                        "text-size": 15,
                        "text-allow-overlap": true,
                    }
                });
            }

            function newPosition(timestamp) {
                for (let i = 0; i < vehiclesSourceData.features.length; i++) {
                    let f = vehiclesSourceData.features[i];
                    if (!f.properties.animationStartTimestamp) {
                        f.properties.animationStartTimestamp = timestamp;
                    }
                    for (let j = f.properties.movementPoints.length - 1; j >= 0; j--) {
                        if (isUpdatedPositionByMovementPoint(j, f, timestamp)) {
                            break;
                        };
                    }
                }
            }

            function isUpdatedPositionByMovementPoint(j, vehicle, timestamp) {
                let applicationTimeInterval = timestamp - vehicle.properties.animationStartTimestamp;
                let vehicleMovementPointsTimeInterval = vehicle.properties.movementPoints[j].timestamp - vehicle.properties.movementPoints[0].timestamp;
                if (applicationTimeInterval >= vehicleMovementPointsTimeInterval) {
                    let long = vehicle.properties.movementPoints[j].geoLocation.longitude;
                    let lat = vehicle.properties.movementPoints[j].geoLocation.latitude;
                    let newcoords = [long, lat];
                    let newPoint = point(newcoords);
                    if (!areCoordinatesTheSame()) {
                        vehicle.properties.icon_rotate = bearing(vehicle, newPoint) - map.getBearing();
                    }
                    vehicle.geometry.coordinates[0] = long;
                    vehicle.geometry.coordinates[1] = lat;
                    newTrailsPosition(vehicle.properties.id, newcoords, timestamp);
                    return true;
                }
                return false;

                function areCoordinatesTheSame() {
                    return (vehicle.properties.movementPoints[j].geoLocation.longitude === vehicle.geometry.coordinates[0] && vehicle.properties.movementPoints[j].geoLocation.latitude === vehicle.geometry.coordinates[1]);
                }
            }

            function newTrailsPosition(id, newcoords, timestamp) {
                let trail = findTrailById(id);
                trail.properties.pointsTimestamp.push(timestamp);
                trail.geometry.coordinates.push(newcoords);
                if (isTrailsShownForMoreThanTwoSeconds(timestamp) && !pauseFlag) {
                    trail.geometry.coordinates.shift();
                    trail.properties.pointsTimestamp.shift();
                }

                function isTrailsShownForMoreThanTwoSeconds(timestamp) {
                    return timestamp - trail.properties.pointsTimestamp[0] >= (1000 * numberOfSecondsToShowTrail);
                }
            }

            function findTrailById(id) {
                let temp = null;
                trailsGreenSourceData.features.forEach(element => {
                    if (element.properties.id === id) {
                        temp = element;
                        return;
                    }
                });
                trailsOrangeSourceData.features.forEach(element => {
                    if (element.properties.id === id) {
                        temp = element;
                        return;
                    }
                });
                trailsRedSourceData.features.forEach(element => {
                    if (element.properties.id === id) {
                        temp = element;
                        return;
                    }
                });
                return temp;
            }

            function generateMorePointsForAnimation(movementPoints) {
                let numberOfMovementPoints = movementPoints.length;
                if (numberOfMovementPoints >= totalPointsInInterval || numberOfMovementPoints < 2) {
                    return movementPoints;
                } else {
                    //we assume that the timestamps are ordered
                    let newMovementPoints = [];
                    const lengthMinusOne = movementPoints.length - 1;
                    for (let i = 0; i < lengthMinusOne; i++) {
                        Array.prototype.push.apply(newMovementPoints, generatePointsBetween(movementPoints[i], movementPoints[i + 1], totalPointsInInterval / lengthMinusOne));
                    }
                    return newMovementPoints;
                }
            }

            function loadVehicleImages(map) {
                let largeGreenImg = new Image();
                largeGreenImg.onload = () => map.addImage('large-green', largeGreenImg);
                largeGreenImg.src = largeGreenSvg;

                let largeOrangeImg = new Image();
                largeOrangeImg.onload = () => map.addImage('large-orange', largeOrangeImg);
                largeOrangeImg.src = largeOrangeSvg;

                let largeRedImg = new Image();
                largeRedImg.onload = () => map.addImage('large-red', largeRedImg);
                largeRedImg.src = largeRedSvg;
            }

            function generatePointsBetween(start, end, steps) {
                let points = [];
                let diffLng = end.geoLocation.longitude - start.geoLocation.longitude;
                let diffLat = end.geoLocation.latitude - start.geoLocation.latitude;
                let diffTime = end.timestamp - start.timestamp;
                for (let i = 0; i <= steps; i++) {
                    let point = JSON.parse(JSON.stringify(start));
                    point.geoLocation = {};
                    point.geoLocation.longitude = start.geoLocation.longitude + diffLng * (i / steps);
                    point.geoLocation.latitude = start.geoLocation.latitude + diffLat * (i / steps);
                    point.timestamp = start.timestamp + diffTime * (i / steps);
                    points.push(point);
                }
                return points;
            }
        }
        if (!map) initializeMap({ setMap, mapContainer });
        return () => {
            PubSub.unsubscribe(subscribeReferece);
            cancelAnimationFrame(mapContainer.current.animationFrameRef); // To avoid memory leak
        }
    }, [map]);

    function calcTotalPoints() {
        if (globalState.configuration && globalState.configuration.vehiclesPollInterval) {
            numberOfSecondsInInterval = globalState.configuration.vehiclesPollInterval / 1000;
            totalPointsInInterval = pointsInSecond * numberOfSecondsInInterval;
        }
    }

    function initRoadWidth(map) {
        if (globalState.configuration && globalState.configuration.roadWidth) {
            map.getStyle().layers.forEach(layer => {
                if (layer["source-layer"] === "road" && layer.paint["line-width"]) {
                    map.setPaintProperty(layer.id, 'line-width', globalState.configuration.roadWidth);
                }

            });
        }
    }

    function getDistanceInKilometers(map) {
        let canvas = map.getCanvas(),
            w = canvas.width,
            h = canvas.height,
            cLL = point(map.unproject([0, h]).toArray()),
            cLR = point(map.unproject([w, h]).toArray());
        const distanceBetween = distance(cLL, cLR, { units: 'kilometers' });
        return distanceBetween;
    }

    function calcLaneSizeInPixels(map, sensorsByLineArray) {
        const mapCanvasWidthPixels = map.getCanvas().width;
        const distanceInKilometers = getDistanceInKilometers(map);
        const kilometerInOnePixel = distanceInKilometers / mapCanvasWidthPixels;
        laneSizeInPixels = distance(point(sensorsByLineArray[0][0]), point(sensorsByLineArray[1][0])) / kilometerInOnePixel;
    }

    function addLocationViewForDebugging(map) {
        if (process.env.NODE_ENV === 'development') {
            map.on('mousemove', function(e) {
                document.getElementById('info').innerHTML =
                    // e.point is the x, y coordinates of the mousemove event relative
                    // to the top-left corner of the map
                    "debug mode: " +
                    JSON.stringify(map.getBearing()) +
                    JSON.stringify(e.point) +
                    '<br />' +
                    // e.lngLat is the longitude, latitude geographical position of the event
                    JSON.stringify(e.lngLat.wrap());
            });
        }
    }

    function handleShowVehiclesTrailsChanged(checked) {
        mapContainer.current.showVehiclesTrails = checked;
        setShowTrails(checked); // a workaround
    }

    return <div id = "map" className = "map-cover" ref = { el => (mapContainer.current = el) }>
                <div className = 'trails-switch-div'>
                    Trail <Switch height = { 15 }
                        width = { 30 }
                        onChange = { handleShowVehiclesTrailsChanged }
                        checked = { showTrails }
                        onColor = '#3399FF'
                        checkedIcon = { false }
                        uncheckedIcon = { false }
                /> 
            </div> 
                <div className = "location-debug" id = "info"> </div>
            </div>
}

export default Map;