import React , { useEffect } from 'react';
import './Tabs.css';

function Tabs(props) {
  const [clickedTab, setClickedTab] = React.useState('Dashboard');
  const tabs = ['Dashboard']; // next version:['Dashboard', 'Light Commands', 'Admin', 'Report'];
  const defaultTabName = 'Dashboard'
  var defaultTab

  //TO focus Dashboard on init
  useEffect(() => {
   defaultTab && defaultTab.focus();
  }, [defaultTab]);

  function onFocus(key) {
    setClickedTab(key);
  }

  return (
    <div className="tabs">
      {tabs.map(key =>
        <button key={key}
          ref={(tab) => {
            if (key === defaultTabName) {
              defaultTab = tab;
            }
          }}
          onFocus={props.action && props.action(key)}
          onClick={() => onFocus(key)}
          className={clickedTab === key ? ' clickable-navbar-button c-F2F6FA bg-3399FF' : 'clickable-navbar-button c-A2A6AA bg-transparent'}>
          {key}
        </button>
      )}
    </div>
  );
}

export default Tabs;