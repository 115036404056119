import moment from 'moment';
import { INVALID_DATE_DAY, INVALID_DATE_MONTH, INVALID_DATE_YEAR, DATE_NOT_EXIST, DATE_NOT_HISTORICAL,
    INVALID_DATE_FORMAT, INVALID_TIME_HOURS, INVALID_TIME_MINUTES, INVALID_TIME_FORMAT, DATE_ERROR_FLAG, TIME_ERROR_FLAG } from '../constants/constants';

const minValidYear = 1902; // move to config
const editedDateFormat = "DD/MM/YYYY";

export const validateTimeObject = (inputDate, inputTime) => {   

    const dateArray = parseDateStringToArray(inputDate);
    if (!dateArray) {
        let errorRes = handleInvalidInput(DATE_ERROR_FLAG, INVALID_DATE_FORMAT + inputDate);
        return {error: errorRes};
    }

    const timeArray = parseTimeStringToArray(inputTime);
    if (!timeArray) {
        let errorRes = handleInvalidInput(TIME_ERROR_FLAG, INVALID_TIME_FORMAT + inputTime);
        return {error: errorRes};
    }

    const timeObjectData = convertToTimeObject(dateArray, timeArray);

    let errorRes = validateTimeObjectValues(timeObjectData, inputDate);  

    return {error: errorRes, timeObject: convertToDateObjectFormat(timeObjectData)};
}

export const parseDateStringToArray = (inputDate) => {
    const dateFormatRegexp = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    return inputDate.match(dateFormatRegexp);
}

export const parseTimeStringToArray = (inputTime) => {
    const timeFormatRegexp = /^(\d{1,2}):(\d{2})([ap]m)?$/;
    return inputTime.match(timeFormatRegexp);
}

const convertToDateObjectFormat = (timeObjectData) => {
    return moment.utc(timeObjectData.year + '-' + timeObjectData.month + '-' + timeObjectData.day + ' ' + timeObjectData.hours + ':' + timeObjectData.minutes, "YYYY-MM-DD HH:mm");
}

const convertToTimeObject = (dateValuesArray, timeValuesArray) => {
    let timeObject = {};

    timeObject.minutes = timeValuesArray[2];
    timeObject.hours = timeValuesArray[1];
    timeObject.day = dateValuesArray[1];
    timeObject.month = dateValuesArray[2];
    timeObject.year = dateValuesArray[3];
    
    return timeObject;
}

const validateTimeObjectValues = (timeObject, inputDate) => {
    if (isMinuteOutOfRange(timeObject.minutes)) {
        return handleInvalidInput(TIME_ERROR_FLAG, INVALID_TIME_MINUTES + timeObject.minutes);
    }
    if (isHourOutOfRange(timeObject.hours)) {
        return handleInvalidInput(TIME_ERROR_FLAG, INVALID_TIME_HOURS + timeObject.hours);
    }
    if (isDayOutOfRange(timeObject.day)) {
        return handleInvalidInput(DATE_ERROR_FLAG, INVALID_DATE_DAY + timeObject.day);
    }
    if (isMonthOutOfRange(timeObject.month)) {
        return handleInvalidInput(DATE_ERROR_FLAG, INVALID_DATE_MONTH + timeObject.month);
    }
    if (isYearOutOfRange(timeObject.year)) {
        return handleInvalidInput(DATE_ERROR_FLAG, stringFormat(INVALID_DATE_YEAR, [timeObject.year, new Date().getFullYear()]));
    }               
    if (isDateNotValid(inputDate)) {
        return handleInvalidInput(DATE_ERROR_FLAG, DATE_NOT_EXIST + inputDate);
    }
    if (isDateNotHistorical(inputDate)) {
        return handleInvalidInput(DATE_ERROR_FLAG, DATE_NOT_HISTORICAL + inputDate);
    }

    return undefined;
}

const handleInvalidInput = (errorFlag, alertText) => {    
    return { errorFlag: errorFlag, alertText: alertText};
}

export const isMinuteOutOfRange = (minutesValue) => {
    return minutesValue > 59
} 

export const isHourOutOfRange = (hoursValue) => { 
    return hoursValue > 23;
}

export const isDayOutOfRange = (dayValue) => {
    return dayValue < 1 || dayValue > 31; 
}

export const isMonthOutOfRange = (monthValue) => { 
    return monthValue < 1 || monthValue > 12; 
}

export const isYearOutOfRange = (yearValue) => {    
    return yearValue < minValidYear || yearValue > (new Date()).getFullYear(); 
}

export const isDateNotValid = (inputDate) => {
    const currentFormattedDate = moment(inputDate, editedDateFormat);    
    return !currentFormattedDate._isValid;
}

export const isDateNotHistorical = (inputDate) => {    
    const currentFormattedDate = moment(inputDate, editedDateFormat).valueOf();
    var yesterday = moment().subtract(1, "days").valueOf();

    return currentFormattedDate > yesterday;
}

const stringFormat = (inputString, args) => {
    const paramsRegexp = /{(\d+)}/g;
    return inputString.replace(paramsRegexp, function(match, number) { 
        return typeof args[number] != 'undefined' ? args[number] : match;
    });
};
