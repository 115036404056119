import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config from './config';
import { Auth } from "aws-amplify";

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        authenticationFlowType: 'USER_SRP_AUTH',
    },
    API: {
        endpoints: [
            {
            name: config.apiGateway.NAME,
            endpoint: config.apiGateway.URL,
            region: config.apiGateway.REGION,
            custom_header: async () => {
                let currentSession = await Auth.currentSession();
                return { Authorization: `${currentSession.idToken.getJwtToken()}` }
            }
        }
    ]
    }

});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
