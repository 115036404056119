
import React from "react";
import MainNavigation from './components/main-navigation/MainNavigation'
import Login from './components/login/Login'
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import AppliedRoute from "./components/common/AppliedRoute";
export default function Routes({ appProps }) {
    return (
        <Router>
            <Switch>
                <AppliedRoute path="/main" exact component={MainNavigation} appProps={appProps} />
                <AppliedRoute path="/login" exact component={Login} appProps={appProps} />
                { /* Finally, catch all unmatched routes */}
                <Redirect to="/login" component={Login} appProps={appProps}  />
            </Switch>
        </Router>
    );
}