import React from 'react';
import './Popup.css';

function Popup(props) {
    return (
        <div className={`${props.styleName}`}>
            {props.content}
        </div>
    );
}

export default Popup;