import React, { useState } from 'react';
import './TimeFilter.css';
import TimeFilterPopup from './time-filter-popup/TimeFilterPopup';
import Popup from '../../../../common/popup/Popup';
import { useGlobal } from "../../../../../services/globalStatesStore";
import HistoricalDataPopup from './time-filter-popup/historical-data-popup/HistoricalDataPopup';
import { HISTORICAL_FLAG, REALTIME_FLAG } from '../../../../../constants/constants';
import { postWeather } from '../../../../../services/apiCalls';

export default function TimeFilter(props) {
    const [globalState, globalActions] = useGlobal();

    const [isOpenHistoricalPopup, setIsOpenHistoricalPopup] = useState(false);
    const [isOpenTimeFilterPopup, setIsOpenTimeFilterPopup] = useState(false);
    
    const onOpenTimeFilter = event => setIsOpenTimeFilterPopup(!isOpenTimeFilterPopup);
    
    const setTimeFilter = (filterType) => {
        if (filterType === HISTORICAL_FLAG) {
            setIsOpenHistoricalPopup(true);
        } else if (filterType === REALTIME_FLAG && globalState.timeFilterRange !== REALTIME_FLAG) {
            globalActions.updateTimeFilterType(filterType);
            initTimestamp();
            postWeather(globalActions.initWeather, {location: globalState.locationData.location, time: Date.now()});
        }

        setIsOpenTimeFilterPopup(false);
    }

    const initTimestamp = () => {
        const nowTime = Date.now();
        const TwentyFourHoursAsMilliseconds = 3600000 * 24;
        globalActions.updateTimestamp({ start: nowTime - TwentyFourHoursAsMilliseconds, end: nowTime });
    }
    const setHistoricalDataRange = (historicalDateRange) => {
        setIsOpenHistoricalPopup(false);
        if (historicalDateRange !== "") {
            globalActions.updateTimeFilterType(historicalDateRange);
        }
    }

    const setWidthClass = (filterType) => {        
        return filterType === REALTIME_FLAG ? "realtime-width" : "historical-width";
    }

    return(
        <div className="time-filter">
            <button className={"time-filter c-DEE2E6 " + setWidthClass(globalState.timeFilterRange)} onClick={ () => {onOpenTimeFilter()}}> {globalState.timeFilterRange} </button>                                        
            { isOpenTimeFilterPopup && 
                <Popup styleName="bottom-left" content={ <TimeFilterPopup setTimeFilterAction={setTimeFilter} currentFilter={globalState.timeFilterRange} /> } />
            }
            { isOpenHistoricalPopup && props.timeData && props.timeData.timezoneData &&   
                <div>
                    <Popup styleName="center" content={ <HistoricalDataPopup setHistoricalDataRange={setHistoricalDataRange} timeData={props.timeData} /> } /> 
                    <div className="blocker"/>               
                </div>                
            }                                           
        </div>        
    );
}