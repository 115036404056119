import PubSub from 'pubsub-js';
import { incidentsDataName } from './../../../../services/pollingService';

const showAllPrioritiesFlag = 0;
const solvedPriorityFlag = 4;
const incidentTypes = ['svd', 'accidents', 'uncategorized', 'wrong-way', 'congestion', 'black-ice', 'predictions'];  // 'animals', 'cavs', -> types to next version. 
const UNCATEGORIZED_TYPE = 'uncategorized';
var incidentAlertsArray = [];

export const subscribeIncidents = (setIncidentsList, setTypesCountDictionary) => {  
    PubSub.subscribe(incidentsDataName, function (msg, incidentsData) {        
        if (typeof incidentsData === 'undefined') {
            console.error("Error: No incidentsData from server");
            return;
        }
        
        incidentAlertsArray = updateIncidentAlertList(incidentsData);
        setIncidentsList(incidentAlertsArray);

        let incidentCountsDictionary = calculateIncidentsCountByType(incidentsData);
        setTypesCountDictionary(incidentCountsDictionary);
    });
}

export function getIncidentAlerts(requestedPriority) { //, requestedTypes) { // TODO: in this version - not filtering by type, only by priority.
    let priorityFilteredResult = filterByPriority(incidentAlertsArray, requestedPriority);
    let filteredIncidentResult = priorityFilteredResult // filterByTypes(priorityFilteredResult, requestedTypes);  // TODO: in this version - not filtering by type, only by priority.
    
    return filteredIncidentResult;  
}

function filterByPriority(incidents, requestedPriority) {
    let filteredIncidentResult = [];
    for (let index = 0; index < incidents.length; index++) {
        const incident = incidents[index];
        if ((requestedPriority === showAllPrioritiesFlag && incident.priority !== solvedPriorityFlag) || (incident.priority === requestedPriority)) {
            filteredIncidentResult.push(incident);
        }
    }

    return filteredIncidentResult;
}

// function filterByTypes(incidents, requestedTypes) {  // TODO: in this version - not filtering by type, only by priority.
//     let filteredIncidentResult = [];
//     for (let index = 0; index < incidents.length; index++) {
//         const incident = incidents[index];
//         if (requestedTypes.includes(incident.type)) {
//             filteredIncidentResult.push(incident);
//         }
//     }

//     return filteredIncidentResult;
// }

function updateIncidentAlertList(incidentAlertsData) {
    let incidentAlertResult = [];
    
    for (let index = 0; index < incidentAlertsData.length; index++) {
        const incident = incidentAlertsData[index];
        validateIncidentsType(incident);        
        incident.iconSrc = getIconByType(incident.type);
        incident.color = getColorByPriority(incident.priority);
        incidentAlertResult.push(incident);
    }

    return incidentAlertResult;
}

function validateIncidentsType(incident) {
    let type = incidentTypes.includes(incident.type);
    if (!type) {
        incident.type = UNCATEGORIZED_TYPE;
    }
}

function getIconByType(type) {
    return 'images/' + type + '-icon-active.svg';
}

function getColorByPriority(priority) {
    const colorDictionary = {
        1: 'palette-bg-yellow',
        2: 'palette-bg-orenge',
        3: 'palette-bg-red',
        4: 'palette-bg-light-green'
    }
  
    return colorDictionary[priority];
}

function calculateIncidentsCountByType(incidentsData) {
    let incidentCountsDictionary = {};

    for (let index = 0; index < incidentsData.length; index++) {
        const incident = incidentsData[index];
        increaseTypeCount(incidentCountsDictionary, incident.type);
    }

    return incidentCountsDictionary;
}

function increaseTypeCount(incidentsDictionary, type) {
    incidentsDictionary[type] = incidentsDictionary[type] === undefined ? 1 : incidentsDictionary[type] +1;
}
