import React from 'react';
import './SecondaryBar.css';
import TimeFilter from './time-filter/TimeFilter';
import LocationFilter from './location-filter/LocationFilter';
// import MapZoom from './map-zoom/MapZoom'; //Next version
import Weather from './weather/Weather';

function SecondaryBar(props) {

    return(
        <div className="secondary-bar bg-2A2E32">
            <div className="bar-left-side">
                <TimeFilter switchTimeFilter={props.switchTimeFilter} timeData={props.timeData}/>
                <div className="left-seperator bg-666A6E"></div>
                <LocationFilter/>
            </div>
            <div className="bar-right-side">
                {/* <MapZoom/> //Next version */}
                {/* <div className="right-seperator bg-666A6E"></div>  //Next version*/}
                <Weather/>
            </div>            
        </div>
    );
}

export default SecondaryBar;