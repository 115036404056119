import React from 'react';
import './IncidentAlertFilterButton.css';

function IncidentAlertFilterButton(props) {        
    return(    
        <div onClick={props.onClick} className={`${props.selected ? 'bg-52565A' : 'bg-2A2E32'} incident-priority-filter-button`}>
            <div className={`${props.color} priority-indicator`} ></div>
        </div>                 
    );
}

export default IncidentAlertFilterButton;