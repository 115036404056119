
import { postUtcOffset } from './apiCalls';
import moment from 'moment';

const timezoneIntervalInMS = 60000 * 30; // 60000 -> 1 minute * 30 = 30 minutes
var lastTimezoneData;
var timezoneIntervalInstance;

export async function getTimezone(currentTimestamp, locationData, dstTransitionHour, callback) {

    const timezoneResponseData = await fetchTimezoneData(currentTimestamp, locationData.location, dstTransitionHour);
    if (timezoneResponseData) {
        callback(timezoneResponseData);
    }
}

async function fetchTimezoneData(currentTimestamp, location, dstTransitionHour) {
    if (isObjectContainValues(location)) {
        return;
    }
    let timezoneResponseData = await getTimezoneData(currentTimestamp, dstTransitionHour, location);

    lastTimezoneData = timezoneResponseData;
    return timezoneResponseData;
};

export const startTimezoneInterval = (intervalFunction) => {
    timezoneIntervalInstance = setInterval(() => {
        intervalFunction();
    }, timezoneIntervalInMS);
}

export const stopTimezoneInterval = () => {
    clearInterval(timezoneIntervalInstance);
}

async function getTimezoneData(timePeriod, dstTransitionHour, location) {
    var startTimezoneData = {
        offset: 0
    }
    var endTimezoneData = {
        offset: 0
    }

    const getRoadTimezoneStartBody = {
        timestamp: convertMilisecondsToSeconds(timePeriod.start),
        latitude: location.latitude,
        longitude: location.longitude
    };
    await postUtcOffset(getRoadTimezoneStartBody, (data) => {
        if (data) startTimezoneData = data;
    });

    const getRoadTimezoneEndBody = {
        timestamp: convertMilisecondsToSeconds(timePeriod.end),
        latitude: location.latitude,
        longitude: location.longitude
    };
    await postUtcOffset(getRoadTimezoneEndBody, (data) => {
        if (data) endTimezoneData = data;
    });

    let timezoneData = {
        startTimezoneData: startTimezoneData,
        endTimezoneData: endTimezoneData
    }
    timezoneData.daylightSavingData = getTimezoneDaylightSavingData(startTimezoneData, endTimezoneData, timePeriod, dstTransitionHour);

    return timezoneData;
}

function getTimezoneDaylightSavingData(startTimezoneData, endTimezoneData, timePeriod, dstTransitionHour) {
    let daylightSavingData = {
        changeMoment: 0,
        isClockChangesInTimePeriod: false,
    }
    if (!startTimezoneData || !endTimezoneData) {
        return daylightSavingData;
    }

    let startTimezoneOffset = startTimezoneData.offset;
    let endTimezoneOffset = endTimezoneData.offset;

    if (startTimezoneOffset === endTimezoneOffset) {
        daylightSavingData.isClockChangesInTimePeriod = false;
        return daylightSavingData;
    }
    daylightSavingData.isClockChangesInTimePeriod = true;

    let winterOffset;
    let isMovingClockForward = startTimezoneOffset < endTimezoneOffset;
    isMovingClockForward ? winterOffset = startTimezoneOffset : winterOffset = endTimezoneOffset;

    let transitionHoursAndMinutes = dstTransitionHour.split(':');
    let transitionHour = transitionHoursAndMinutes[0];
    let transitionMinute = transitionHoursAndMinutes[1];
    daylightSavingData.changeMoment = moment.utc(timePeriod.end).startOf('day')
        .add(transitionHour, 'hours')
        .add(transitionMinute, 'minute')
        .add(-winterOffset, 'seconds').valueOf();

    return daylightSavingData;
}

function convertMilisecondsToSeconds(Miliseconds) {
    return Math.floor(Miliseconds / 1000);
}

function isObjectContainValues(obj) {
    return Object.keys(obj).length === 0;
}