import React from 'react';
import './Weather.css'
import { useGlobal } from "../../../../../services/globalStatesStore";

const DEFAULT_DEGREES = 23;
const weatherIconsDictionary = {
    1: 'weatherIcons/sunny.svg',
    2: 'weatherIcons/mostly-sunny.svg',
    3: 'weatherIcons/partly-sunny.svg',
    4: 'weatherIcons/intermittent-clouds.svg',
    5: 'weatherIcons/hazy-sunshine.svg',
    6: 'weatherIcons/mostly-cloud.svg',
    7: 'weatherIcons/cloudly.svg',
    8: 'weatherIcons/dreary-overcast.svg',
    11: 'weatherIcons/fog.svg',
    12: 'weatherIcons/showers.svg',
    13: 'weatherIcons/mostly-cloudy-with-showers.svg',
    14: 'weatherIcons/partly-sunny-with-showers.svg',
    15: 'weatherIcons/thunderstorm.svg',
    16: 'weatherIcons/mostly-cloudy-with-thunder-storms.svg',
    17: 'weatherIcons/partly-sunny-with-thunder-storms.svg',
    18: 'weatherIcons/rain.svg',
    19: 'weatherIcons/flurries.svg',
    20: 'weatherIcons/mostly-cloudy-with-flurries.svg',
    21: 'weatherIcons/partly-sunny-with-flurries.svg',
    22: 'weatherIcons/snow.svg',
    23: 'weatherIcons/mostly-cloudy-with-snow.svg',
    24: 'weatherIcons/ice.svg',
    25: 'weatherIcons/sleet.svg',
    26: 'weatherIcons/freezing-rain.svg',   
    29: 'weatherIcons/rain-and-snow.svg',
    30: 'weatherIcons/hot.svg',
    31: 'weatherIcons/cold.svg',
    32: 'weatherIcons/windy.svg',
    33: 'weatherIcons/clear-night.svg',
    34: 'weatherIcons/mostly-clear-night.svg',
    35: 'weatherIcons/partly-cloudy-night.svg',
    36: 'weatherIcons/intermittent-clouds-night.svg',
    37: 'weatherIcons/hazy-moonlight.svg',
    38: 'weatherIcons/mostly-cloudy-night.svg',
    39: 'weatherIcons/partly-cloudy-with-showers-night.svg',
    40: 'weatherIcons/mostly-cloudy-with-showers-night.svg',
    41: 'weatherIcons/partly-cloudy-with-storms-night.svg',
    42: 'weatherIcons/mostly-cloudy-with-storms-night.svg',
    43: 'weatherIcons/mostly-cloudy-with-flurries-night.svg',
    44: 'weatherIcons/mostly-cloudy-with-snow-night.svg'
}

export default function Weather() {
    const [globalState] = useGlobal();
    var weatherIcon = globalState.WeatherData && globalState.WeatherData.iconCode ? weatherIconsDictionary[globalState.WeatherData.iconCode] : weatherIconsDictionary[1] ;
    var degrees = globalState.WeatherData ? globalState.WeatherData.degrees : DEFAULT_DEGREES; 
    return(
        <div className="weather">
            <div className="degrees-label c-F2F6FA">{Math.round(degrees)}° </div>
            <img className="weather-icon" src={weatherIcon} alt=""/>
        </div>
    )
}