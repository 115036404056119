
import React from "react";
import './IncidentAlertPopup.css'
import { useGlobal } from "../../../../../services/globalStatesStore";

export default function IncidentAlertPopup(props) {

    const [globalState,] = useGlobal();

    const openUrl = (incidentId) => {
        let url = globalState.configuration.incidentUrl + incidentId;
        var win = window.open(url, '_blank');
        win.focus();
        closePopup();
    }

    const closePopup = () => {
        props.setIsOpenIncidentAlertPopup(false);
    }

    return (
        <div className='incident-alert-popup'>
            <div className='popup-title'>
                More details?
            </div>
            <div className='flex-space-around-content'>
                <button className='popup-action-button' disabled={!globalState.configuration.incidentUrl} onClick={() => openUrl(props.incidentId)}>YES</button>
                <button className='popup-action-button' onClick={() => closePopup()}>NO</button>
            </div>
        </div>
    )
}
