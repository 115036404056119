import PubSub from 'pubsub-js';
import { postPollTimeline, postPollIncidents } from '../../../../services/apiCalls';
import { timelineDataName } from './../../../../services/pollingService';
import { incidentsDataName } from './../../../../services/pollingService';
import * as timelineUtils from './timelineUtils';

const oneHourAsMillis = 3600000;
const fourHoursAsMillis = 14400000;
const oneMinuteInMS = 60000;
const twoSecondsInMS = 2000;

const priorityDictionary = {
    1: 'High',
    2: 'Medium',
    3: 'Low'
}

const firstLineSeriesIndex = 0;
const secondLineSeriesIndex = 1;
const firstPredictionLineSeriesIndex = 2;
const secondPredictionLineSeriesIndex = 3;
const pastIncidentsSeriesIndex = 4;
const predictionsIncidentsSeriesIndex = 5;
const solvedPriorityToIgnore = 4;

var timelineDataDictionary = {};
var subscribeToken;
var aliveIncidents = {};

export const initDataPolling = (timePeriod, buildChartAfterUpdateCallback, realTimeDelay, isHistoricalMode) => {
    initTimelineDataDictionary();
    const pollInsidentRequstBody = {
        start: timePeriod.end,
        end: timePeriod.end + twoSecondsInMS
    };
    postPollIncidents((incidents) => {
        aliveIncidents = incidents;
        pollTimelineData(timePeriod, buildChartAfterUpdateCallback, realTimeDelay, isHistoricalMode);
    }, pollInsidentRequstBody);
}

function pollTimelineData(timePeriod, buildChartAfterUpdateCallback, realTimeDelay, isHistoricalMode) {
    const pollTimelinePastBody = {
        start: timePeriod.start,
        end: timePeriod.end
    };
    const pollTimelineFutureBody = {
        start: timePeriod.end - oneMinuteInMS - realTimeDelay,
        end: timePeriod.end - realTimeDelay
    };
    postPollTimeline((hours24Data) => {
        parse24HoursTimelineData(hours24Data);
        buildChartAfterUpdateCallback();
    }, pollTimelinePastBody);

    if (!isHistoricalMode) {
        postPollTimeline((futureData) => {
            parseRealtimeData(futureData);
        }, pollTimelineFutureBody);
    }
}

export const subscribeTimeline = (chartRef) => {
    subscribeToken = PubSub.subscribe(timelineDataName, function (msg, timelineRealTimeData) {
        if (typeof timelineRealTimeData === 'undefined') {
            console.error("Error: No timelineData from server");
            return;
        }
        if (!chartRef.series) {
            return;
        }
        updatePredictionData(chartRef, timelineRealTimeData);
        updatePastData(chartRef, timelineRealTimeData);
    });

    PubSub.subscribe(incidentsDataName, function (msg, incidentsData) {
        if (typeof incidentsData === 'undefined') {
            return;
        }

        aliveIncidents = incidentsData;
    });
}

export const unsubscribeTimeline = () => {
    PubSub.unsubscribe(subscribeToken);
}

function updatePastData(chartRef, timelineRealTimeData) {
    let lastData = timelineRealTimeData.pastData;

    if (!lastData || !lastData[0]) {
        console.error("Error: No lastMinute data from server");
        return;
    }

    let timestampToRemove = chartRef.series && chartRef.series[0].data[0].x;

    shiftPastSeriesData(chartRef.series[firstLineSeriesIndex], [lastData[0].timestamp, lastData[0].vehicleCount]);
    shiftPastSeriesData(chartRef.series[secondLineSeriesIndex], [lastData[0].timestamp, lastData[0].averageSpeed]);
    shiftPastIncidents(chartRef, timestampToRemove, lastData[0].incidentAlerts, lastData[0].timestamp);
    timelineUtils.updateChartCursorPosition(chartRef, lastData[0].timestamp);
    timelineUtils.updateChartPlotBands(chartRef, timestampToRemove, lastData[0].timestamp);
    updatePastIncidentsStatus(chartRef);
    chartRef.redraw();
}

function shiftPastSeriesData(series, pointData) {
    series.addPoint(pointData, false, true, false, false);
}

function shiftPastIncidents(chartRef, timestampToRemove, newIncidents, newTimestamp) {
    removeOldIncidents(chartRef, timestampToRemove);
    addNewIncidents(chartRef, newIncidents, newTimestamp, pastIncidentsSeriesIndex);
}

function removeOldIncidents(chartRef, timestampToRemove) {
    const series = chartRef.series[pastIncidentsSeriesIndex];
    if (series.data[0] && series.data[0].x === timestampToRemove) {
        series.data[0].remove();
    }
}

function addNewIncidents(chartRef, newIncidents, newTimestamp, incidentsSeriesIndex) {
    if (newIncidents && newIncidents.length > 0) {
        for (let incidentsIndex = 0; incidentsIndex < newIncidents.length; incidentsIndex++) {
            if (newIncidents[incidentsIndex].priority === solvedPriorityToIgnore) {
                continue;
            }
            const newIncident = createNewIncident(newIncidents[incidentsIndex].priority, newTimestamp, newIncidents[incidentsIndex].type, newIncidents[incidentsIndex].id);
            chartRef.series[incidentsSeriesIndex].addPoint(newIncident, false, false, false, false);
        }
    }
}

function updatePredictionData(chartRef, timelineRealTimeData) {

    if (!timelineRealTimeData || !timelineRealTimeData.futureData) {
        return;
    };

    clearPreviousIncidents(chartRef);

    let firstPredictionsLine = [];
    let secondPredictionsLine = [];

    const futureData = timelineRealTimeData.futureData;

    for (let index = 0; index < futureData.length; index++) {
        firstPredictionsLine.push([futureData[index].timestamp, futureData[index].vehicleCount]);
        secondPredictionsLine.push([futureData[index].timestamp, futureData[index].averageSpeed]);

        addNewIncidents(chartRef, futureData[index].incidentAlerts, futureData[index].timestamp, predictionsIncidentsSeriesIndex);
    }

    chartRef.series[firstPredictionLineSeriesIndex].setData(firstPredictionsLine, false, false, true);
    chartRef.series[secondPredictionLineSeriesIndex].setData(secondPredictionsLine, false, false, true);
}

const clearPreviousIncidents = (chartRef) => {
    chartRef.series[predictionsIncidentsSeriesIndex].setData([]);
}

const parseRealtimeData = (realtimeData) => {
    if (!realtimeData || !realtimeData.futureData) {
        return;
    }

    let futureData = realtimeData.futureData;

    for (let i = 0; i < futureData.length; i++) {
        const element = futureData[i];

        let vehicleCountValue = [element.timestamp, element.vehicleCount];
        timelineDataDictionary.firstPredictionsLine.push(vehicleCountValue);

        let averageSpeedValue = [element.timestamp, element.averageSpeed];
        timelineDataDictionary.secondPredictionsLine.push(averageSpeedValue);

        let incidentAlerts = element.incidentAlerts;
        if (incidentAlerts) {
            incidentAlerts.forEach(incident => addIncident(incident, element.timestamp, 'predictionsIncidents'));
        }
    }
}

const parse24HoursTimelineData = (timelineData) => {
    updateYaxisTicks(timelineData);

    let pastData = timelineData.pastData;

    if (!pastData) {
        return;
    }

    for (let i = 0; i < pastData.length; i++) {
        const element = pastData[i];

        let vehicleCountValue = [element.timestamp, element.vehicleCount];
        timelineDataDictionary.firstLine.push(vehicleCountValue);

        let averageSpeedValue = [element.timestamp, element.averageSpeed];
        timelineDataDictionary.secondLine.push(averageSpeedValue);

        let incidentAlerts = element.incidentAlerts;
        if (incidentAlerts && incidentAlerts.length > 0) {
            incidentAlerts.forEach(incident => addIncident(incident, element.timestamp, 'pastIncidents'));
        }
    }
}

function updateYaxisTicks(timelineData) {
    if (!timelineData || !timelineData.speedTicks || !timelineData.vehicleCountTicks) {
        return;
    }

    timelineDataDictionary.speedTicks = timelineData.speedTicks;
    timelineDataDictionary.vehicleCountTicks = timelineData.vehicleCountTicks;
}

const addIncident = (incident, timestamp, collectionType) => {
    let incidentPriority = incident.priority;
    if (incidentPriority === solvedPriorityToIgnore) {
        return;
    }
    const newIncident = createNewIncident(incidentPriority, timestamp, incident.type, incident.id);
    timelineDataDictionary[collectionType].push(newIncident);
}

const createNewIncident = (incidentPriority, timestamp, incidentType, incidentId) => {
    let priorityFlag = priorityDictionary[incidentPriority];
    let incidentStatus = getIncidentStatus(timestamp, incidentId);
    let incidentMarker = timelineUtils.getIncidentMarker(priorityFlag, incidentStatus, incidentType);
    let newIncident = {
        x: timestamp,
        y: 0,
        id: incidentId,
        marker: incidentMarker,
        status: incidentStatus,
        priority: priorityFlag,
        type: incidentType,
    }

    return newIncident;
}

const getIncidentStatus = (incidentTimestamp, incidentId) => {
    let now = Date.now();
    // let diffBetweenNow = now - incidentTimestamp;
    let incidentStatus;

    // TODO: refactor to avoid multiple if else
    if (aliveIncidents.length === 0 || !isIncidentAlive(incidentId)) {
        incidentStatus = 'past';
    }
    else {
        incidentStatus = 'ongoing';
    }
    // else if (diffBetweenNow <= oneHourAsMillis) {
    //     incidentStatus = 'new';
    // } else if (diffBetweenNow <= fourHoursAsMillis) {
    //     incidentStatus = 'ongoing';
    // } else {
    //     incidentStatus = 'past';
    // }

    return incidentStatus;
}

const updatePastIncidentsStatus = (chartRef) => {
    var now = Date.now();
    let incidentsData = chartRef.series[pastIncidentsSeriesIndex].data;

    for (let index = 0; index < incidentsData.length; index++) {
        const incident = incidentsData[index];
        if (!incident) {
            continue;
        }
        let incidentTimestamp = incident.x;
        let timeSinceIncidentOccured = now - incidentTimestamp;
        if (incident.priority === solvedPriorityToIgnore) {
            continue;
        }
        if (aliveIncidents.length === 0 || !isIncidentAlive(incident.id)) {
            timelineUtils.setElementMarkerByStatus(incident, 'past');
        }
        else if (incident.status === 'new') {
            if (timeSinceIncidentOccured > oneHourAsMillis && timeSinceIncidentOccured < fourHoursAsMillis) {
                timelineUtils.setElementMarkerByStatus(incident, 'ongoing');
            }
        } else if (incident.status === 'ongoing') {
            if (timeSinceIncidentOccured > fourHoursAsMillis) {
                timelineUtils.setElementMarkerByStatus(incident, 'past');
            }
        }
    }
}

const isIncidentAlive = (incidentId) => {
    if (!aliveIncidents  || Object.keys(aliveIncidents).length === 0) {
        return false;
    }    
    return aliveIncidents.some(incident => incident.id === incidentId);
}

const initTimelineDataDictionary = () => {
    timelineDataDictionary = {
        firstLine: [],
        secondLine: [],
        firstPredictionsLine: [],
        secondPredictionsLine: [],
        pastIncidents: [],
        predictionsIncidents: [],
        speedTicks: [],
        vehicleCountTicks: [],
    }
}

export const getFirstLineData = () => {
    return timelineDataDictionary.firstLine;
}

export const getSecondLineData = () => {
    return timelineDataDictionary.secondLine;
}

export const getPredictionsFirstLineData = () => {
    return timelineDataDictionary.firstPredictionsLine;
}

export const getPredictionsSecondLineData = () => {
    return timelineDataDictionary.secondPredictionsLine;
}

export const getPastIncidentsData = () => {
    return timelineDataDictionary.pastIncidents;
}

export const getPredictionsIncidentsData = () => {
    return timelineDataDictionary.predictionsIncidents;
}

export const getSpeedTicksData = () => {
    return timelineDataDictionary.speedTicks;
}

export const getVehicleCountTicksData = () => {
    return timelineDataDictionary.vehicleCountTicks;
}