import React, { useState, useEffect }  from 'react';
import './IncidentAlertCategoryFilter.css';
import IncidentAlertCategoryButton from './incident-alert-category-button/IncidentAlertCategoryButton';
import categoryFilterList from '../../../../../models/categoryFilterList';

function IncidentAlertCategoryFilter(props) {
    const [selectedFilterList, setSelectedFilterList] = useState([]);

    function filterSelected(filterType) {
        if (selectedFilterList.indexOf(filterType) >= 0) {
            let filteredList = selectedFilterList.filter(e => e !== filterType);
            setSelectedFilterList(filteredList);
        }
        else {
            selectedFilterList.push(filterType);
            setSelectedFilterList(Array.from(selectedFilterList));
        }        
    }

    function isSelected(filterType) {
        return selectedFilterList.indexOf(filterType) >= 0;
    }   

    function setCategoryFilterCount(filter) {
        filter.alertsCount = props.incidentsCountDictionary[filter.type] ? props.incidentsCountDictionary[filter.type] : 0;
        return filter;
    }

    useEffect(() => {
        props.updateSelectedTypes(selectedFilterList);
    }, [selectedFilterList, props]); 

    return (
        <div className="incident-alert-category-filter">
            {categoryFilterList.map(filter =>                 
                <IncidentAlertCategoryButton key={filter.type}
                    filterData={setCategoryFilterCount(filter)}
                    selected={isSelected(filter.type)}
                    className={`filter-buttons-space`}
                    onClick={() => filterSelected(filter.type)}>
                </IncidentAlertCategoryButton>
            )}
        
        </div>
    );
}

export default IncidentAlertCategoryFilter;
