       export const categoryFilterList = [   
    {
        id: 0, type :'predictions', title: 'Prediction/s', activeIcon: 'images/predictions-icon-active.svg', passiveIcon:'images/predictions-icon-passive.svg', alertsCount: 0, borderColorClass: 'palette-br-purple',
    },
    {
        id: 1, type :'svd', title: 'SVD', activeIcon: 'images/svd-icon-active.svg', passiveIcon:'images/svd-icon-passive.svg', alertsCount: 0, borderColorClass: 'palette-br-red',
    },
    {
        id: 2, type :'accidents', title: 'Accident/s', activeIcon: 'images/accidents-icon-active.svg', passiveIcon:'images/accidents-icon-passive.svg', alertsCount: 0, borderColorClass: 'palette-br-red',
    },    
    {
        id: 3, type :'wrong-way', title: 'Wrong Way', activeIcon: 'images/wrong-way-icon-active.svg', passiveIcon:'images/wrong-way-icon-passive.svg', alertsCount: 0, borderColorClass: 'palette-br-red',
    },    
    {
        id: 4, type :'congestion', title: 'Congestion', activeIcon: 'images/congestion-icon-active.svg', passiveIcon:'images/congestion-icon-passive.svg', alertsCount: 0, borderColorClass: 'palette-br-orenge',
    },
    {
        id: 5, type :'black-ice', title: 'Black Ice', activeIcon: 'images/black-ice-icon-active.svg', passiveIcon:'images/black-ice-icon-passive.svg', alertsCount: 0, borderColorClass: 'br-0064FF',
    },
    {
        id: 6, type :'uncategorized', title: 'uncategorized', activeIcon: 'images/uncategorized-icon-active.svg', passiveIcon:'images/uncategorized-icon-passive.svg', alertsCount: 0, borderColorClass: 'palette-br-red',
    },
    // {
    //     id: 7, type :'animals', title: 'Animal/s', activeIcon: 'images/animals-icon-active.svg', passiveIcon:'images/animals-icon-passive.svg', alertsCount: 0, borderColorClass: 'palette-br-red',
    // },
    // {
    //     id: 8, type :'cavs', title: 'CAVs', activeIcon: 'images/cavs-icon-active.svg', passiveIcon:'images/cavs-icon-passive.svg', alertsCount: 0, borderColorClass: 'br-5BC1FF',
    // },
]

export default  categoryFilterList;