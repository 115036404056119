import React from "react";
import useGlobalHook from "./useGlobalHook";
import * as actions from "./globalStateActions";

const nowTime = Date.now();
const TwentyFourHoursAsMilliseconds = 3600000 * 24;
const lastTwentyFourHoursTime = nowTime - TwentyFourHoursAsMilliseconds;

const initialState = {
    user: { id: 0, email: "no data", type: "no data", room: "no data" },
    timeFilterRange: "RealTime",
    zoomType: "Zoom In",
    WeatherData: { degrees:"21", iconCode: 1},
    currentTimestamp: { start: lastTwentyFourHoursTime, end: nowTime },
    configuration: {},
    locationData: { road:"M1 - New York City, New York, USA", km:"200 - 300", direction:"none", location: {}},
};

export const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;