import React from 'react';
import moment from 'moment';
import './IncidentAlertListItem.css';

function IncidentAlertListItem(props) {
    const incident = props.incidentData;

    const openIncidentAlertPopup = (eventArgs, incidentId) => {
        props.setIncidentIdToOpen(incidentId);
        props.setIsOpenIncidentAlertPopup(true);

        let popupTopOffset = calculatePopupTopOffset(eventArgs);                            
        document.getElementById("incidentListAlertPopup").style.marginTop = `${popupTopOffset}px`;
    }

    function calculatePopupTopOffset (eventArgs) {
        let elementPosition = eventArgs.currentTarget.getBoundingClientRect();
        let topOffsetOfTheFirstIncidentItem = 184;
        return elementPosition.top - topOffsetOfTheFirstIncidentItem;
    }

    return (
        <div className='incident-alert-list-item bg-2A2E32' onClick={(event) => { openIncidentAlertPopup(event, incident.id) }}>
            <div className={`priority-mark ${incident.color}`}/>
            <div className='incident-icon'>
                <img src={incident.iconSrc} alt=""/>
            </div>
            <div className='time-range-group'>
                <div className='time-label c-B6BABE'>{moment.utc(incident.time).format("HH:mm")}</div>
                <div className='range-label c-B6BABE'>{incident.range}</div>
            </div>            
            <div className='direction-label c-B6BABE'>{incident.direction}</div>
        </div>
    );
}

export default IncidentAlertListItem;