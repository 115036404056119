import React, { useState } from 'react';
import './HistoricalDataPopup.css';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import { useGlobal } from "../../../../../../../services/globalStatesStore";
import { validateTimeObject } from '../../../../../../../services/timeValidatorService';
import { DATE_ERROR_FLAG, TIME_ERROR_FLAG, DATE_OR_TIME_MISSING } from '../../../../../../../constants/constants';
import { postWeather } from '../../../../../../../services/apiCalls';

export default function HistoricalDataPopup(props) {
    const editedDateFormat = "DD/MM/YYYY";
    const hoursCountFromStart = 24;
    const [globalState, globalActions] = useGlobal();
    const defaultTimezoneData = {
        startTimezoneData: {
            offset: 0
        },
        endTimezoneData: {
            offset: 0
        }
    }
    const timezoneData = getTimezoneIfExist(props.timeData);

    const [currentDate, setCurrentDate] = useState(moment.utc());
    const [currentTime, setCurrentTime] = useState(moment.utc().add(timezoneData.endTimezoneData.offset,'seconds').format("HH:mm"));
    const [editedDate, setEditedDate] = useState(moment.utc().format(editedDateFormat));
    const [wholeDate, setWholeDate] = useState(moment.utc());
    const [isDisabled, setIsDisabled] = useState(true);

    let dateInputContainer;
    let timeInputContainer;

    const applayDateRange = () => {
        let timePeriod = {};
        timePeriod.start = moment.utc(wholeDate).valueOf();
        timePeriod.end = moment.utc(wholeDate).add(hoursCountFromStart, 'hours').valueOf();
        globalActions.updateTimestamp(timePeriod);
        props.setHistoricalDataRange(buildHistoricalDateRangeString(wholeDate));
        postWeather(globalActions.initWeather, { location: globalState.locationData.location, time: timePeriod.start });
    }

    const buildHistoricalDateRangeString = (wholeDate) => {
        let dateTimeFormat = 'MMM DD YYYY, HH:mm';
        return moment.utc(wholeDate).add(timezoneData.startTimezoneData.offset,'seconds').format(dateTimeFormat).valueOf() + " - " + moment.utc(wholeDate).add(1, 'days').add(timezoneData.endTimezoneData.offset,'seconds').format(dateTimeFormat).valueOf();
    }

    const selectDateRange = () => {

        if (editedDate === "" || currentTime === "") {
            setIsDisabled(true);
            alert(DATE_OR_TIME_MISSING);
            return;
        }

        const response = validateTimeObject(editedDate, currentTime);
        if (!response.error) {
            setCurrentDate(moment.utc(editedDate, editedDateFormat));
            setWholeDate(moment.utc(response.timeObject).add(-timezoneData.endTimezoneData.offset,'seconds'));
            setIsDisabled(false);
        } else {
            let errorObj = response.error;
            setIsDisabled(true);
            alert(errorObj.alertText);
            setFocusOnInvalidText(errorObj.errorFlag)
        }
    }

    const setFocusOnInvalidText = (errorFlag) => {
        if (errorFlag === DATE_ERROR_FLAG) {
            setFocusInvalidDate();
        } else if (errorFlag === TIME_ERROR_FLAG) {
            setFocusInvalidTime();
        }
    }

    const setFocusInvalidDate = () => {
        dateInputContainer.focus();
        dateInputContainer.setSelectionRange(0, 0);
    }

    const setFocusInvalidTime = () => {
        timeInputContainer.focus();
        timeInputContainer.setSelectionRange(0, 0);
    }

    const onInputDateKeyUp = (input) => {
        const dayMaskRegexp = /^\d{2}$/;
        const monthMaskRegexp = /^\d{2}\/\d{2}$/;
        let date = input.target.value;
        if (date.match(dayMaskRegexp) !== null) {
            input.target.value = date + '/';
        } else if (date.match(monthMaskRegexp) !== null) {
            input.target.value = date + '/';
        }

        setEditedDate(input.target.value);
    }

    const onInputTimeKeyUp = (input) => {
        const minuteMaskRegexp = /^\d{2}$/;
        let time = input.target.value;
        if (time.match(minuteMaskRegexp) !== null) {
            input.target.value = time + ':';
        }

        setCurrentTime(input.target.value);
    }

    const onPopupClose = () => {
        props.setHistoricalDataRange("");
    }

    function getTimezoneIfExist(timeData){
        if (timeData && timeData.timezoneData){
            return props.timeData.timezoneData;
        }
        else{
            return defaultTimezoneData;
        }
    }

    return (
        <div className='historical-data-popup'>
            <div className="close-button" onClick={() => onPopupClose()} />
            <div className="historical-data-label">Historical Data</div>            
            <div className="select-range-label">Select 24 hours range</div>
            <pre className="date-categories">Day Month Year  HH MM</pre>
            <div className="from-row">
                <div className="from-label">From: </div>
                <input ref={(input) => { dateInputContainer = input; }} className="historical-input date" type="text" name="inputDateContainer"
                    placeholder="dd/mm/yyyy" maxLength="10" autoComplete="off" value={editedDate} onChange={onInputDateKeyUp}>                    
                </input>
                <input ref={(input) => { timeInputContainer = input; }} className="historical-input hour" type="text" name="inputTimeContainer" 
                    placeholder="hh:mm" maxLength="5" autoComplete="off" value={currentTime} onChange={onInputTimeKeyUp}>
                </input>
            </div>  
            <div className="to-row">
                <div className="to-label">To: </div>
                <div className="to-label-content">24 hours forward</div>
            </div> 
            <button className="historical-button select" onClick={() => {selectDateRange()}}>SELECT</button>
            <div className="calender-date-range">
                <DateRange style={{background: "transparent"}}                                       
                    calendars={1}                    
                    rangeColors={['#698CFE']}                  
                    color={'transparent'}
                    format={"DD/MM/YYYY"}
                    offsetPositive={false}
                    rangedCalendars={true}                  
                    startDate={currentDate ? moment(currentDate) : moment()}
                    endDate={currentDate ? moment(currentDate).add(1, 'days') : moment().add(1, 'days')}                    
                />
            </div>
            
            <button className="historical-button apply" disabled={ isDisabled } onClick={() => {applayDateRange()}}>APPLY</button>
        </div>
    )
}