export const updateUserProfile = (store, newUser) => {
  if(newUser) {
    store.setState({ user: newUser });
  }
};

export const initUserProfile = (store, userData) => {
  store.setState({ user: userData});
}

export const initConfiguration = (store, configData) => {
  store.setState({ configuration: configData });
}

export const initLocation = (store, locationData) => {
  store.setState({ locationData: locationData});
}

export const initWeather = (store, weatherData) => {
  store.setState({ WeatherData: weatherData });
}

export const updateTimeFilterType = (store, newFilterType) => {
  //TODO: get from API
  store.setState({ timeFilterRange: newFilterType });
};

export const updateZoomType = (store, newZoomType) => {
  //TODO: get from API
  store.setState({ zoomType: newZoomType });
};

export const updateWeatherData = (store, newWeatherData) => {
  //TODO: get from API
  store.setState({ WeatherData: newWeatherData });
};

export const updateTimestamp = (store, newTimestamp) => {
  store.setState({ currentTimestamp: newTimestamp });
}