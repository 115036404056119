import React from 'react';
import './LocationFilter.css'
import { ROAD, KM, DIRECTION } from '../../../../../constants/constants';
import { useGlobal } from "../../../../../services/globalStatesStore";

function LocationFilter() {
    const [globalState] = useGlobal();
    const road = globalState.locationData ? globalState.locationData.road : "no road";
    const km = globalState.locationData ? globalState.locationData.km : "no km";
    const direction = globalState.locationData ? globalState.locationData.direction : "no direction";
    return(
        <div className="location-filter c-DEE2E6">
            <div className="location-label">{ROAD}: {road} </div>
            <div className="next-sign">»</div>
            <div className="location-label">{KM}: {km} </div>
            <div className="next-sign">»</div>
            <div className="location-label">{DIRECTION}: {direction} </div>
        </div>
    )
}

export default LocationFilter;