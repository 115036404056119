import React, { useState, useEffect } from "react";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import './App.css';

function App(props) { 
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (!isAuthenticating &&
    <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
  );
}

export default App;
