import { postPollVehicles, postPollTimeline, postPollIncidents } from './apiCalls';
import PubSub from 'pubsub-js'

var pollVehiclesAndIncidentsInterval = 0;
var pollTimelineInterval = 0;
var vehicelsAndIcidentsPublishTimeInterval = 0;
var timelinePublishTimeInterval = 0;
var initialTimelineDelayBeforePublishing = 0;
var initialVehiclesDelayBeforePublishing = 0;

var vehiclesData = [];
var timelineData = [];
var incidentsData = [];

var pollTimeoutRefereces = {};
var publishTimeoutRefereces = {};

const bufferSize = 2;

export const vehiclesDataName = 'vehiclesData';
export const timelineDataName = 'timelineData';
export const incidentsDataName = 'incidentsData';

var lastTimePeriod;

export const startPollingTimeline = (timePeriod, interval) => {
    pollTimelineInterval = interval;
    timelinePublishTimeInterval = pollTimelineInterval;
    initialTimelineDelayBeforePublishing = pollTimelineInterval;
    setTimeout(initPolling, pollTimelineInterval, timePeriod, postTimeline, pollTimelineInterval); //initPolling(timePeriod, postTimeline, pollTimelineInterval);
    startPublishingTimeline(initialTimelineDelayBeforePublishing);
}

export const startPollingVehiclesAndIncidents = (timePeriod, interval) => {
    vehiclesData.length = 0;
    incidentsData.length = 0;
    pollVehiclesAndIncidentsInterval = interval;
    vehicelsAndIcidentsPublishTimeInterval = pollVehiclesAndIncidentsInterval;
    initialVehiclesDelayBeforePublishing = pollVehiclesAndIncidentsInterval;
    initPolling(timePeriod, postVehiclesAndIncidents, pollVehiclesAndIncidentsInterval);
    setTimeout(startPublishingVehiclesAndIcedents, pollVehiclesAndIncidentsInterval*bufferSize ,initialVehiclesDelayBeforePublishing);
}

const startPollingVehiclesAndIncidentsWithoutExtraBuffer = (timePeriod, interval) => {
    initPolling(timePeriod, postVehiclesAndIncidents, interval);
    startPublishingVehiclesAndIcedents(initialVehiclesDelayBeforePublishing);
}

export const stopPollingTimeline = () => {
    timelineData.length = 0;
    clearTimeout(pollTimeoutRefereces[postTimeline.name]);
    clearTimeout(publishTimeoutRefereces[timelineDataName]);
}

export const stopPollingVehiclesAndIncidents = () => {
    clearTimeout(pollTimeoutRefereces[postVehiclesAndIncidents.name]);
    clearTimeout(publishTimeoutRefereces[vehiclesDataName]);
    clearTimeout(publishTimeoutRefereces[incidentsDataName]);
}

export const startPollingVehiclesAndIncidentsFromLastInterval  = (interval) => {
    startPollingVehiclesAndIncidentsWithoutExtraBuffer(lastTimePeriod, interval);
}

const initPolling = (timePeriod, postMethod, pollInterval) => {
    poll(timePeriod, postMethod, pollInterval);
}

const poll = (timePeriod, postMethod, pollInterval) => {
    postMethod(timePeriod);
    let newTimePeriod = increaseTimePeriod(timePeriod);
    pollTimeoutRefereces[postMethod.name] = setTimeout(poll, pollInterval, newTimePeriod, postMethod, pollInterval);
}

const postVehiclesAndIncidents = (timePeriod) => {
    lastTimePeriod = timePeriod;
    postVehicles(timePeriod);
    postIncidents(timePeriod);
}

const startPublishingVehiclesAndIcedents = (initialDelay) => {
    publishData(vehiclesData, vehiclesDataName, vehicelsAndIcidentsPublishTimeInterval);
    publishData(incidentsData, incidentsDataName, vehicelsAndIcidentsPublishTimeInterval);
    //setTimeout(publishData, 0, vehiclesData, vehiclesDataName, vehicelsAndIcidentsPublishTimeInterval);
    //setTimeout(publishData, 0, incidentsData, incidentsDataName, vehicelsAndIcidentsPublishTimeInterval);
}

const startPublishingTimeline = (initialDelay) => {
    setTimeout(publishData, initialDelay, timelineData, timelineDataName, timelinePublishTimeInterval);
}

const publishData = (dataArray, dataName, publishTimeInterval) => {
    checkAndPublish(dataArray, dataName);
    publishTimeoutRefereces[dataName] = setTimeout(publishData, publishTimeInterval, dataArray, dataName, publishTimeInterval);
}

const increaseTimePeriod = (timePeriod) => {
    const intervalToIncrease = timePeriod.end - timePeriod.start;
    timePeriod.start = timePeriod.start + intervalToIncrease;
    timePeriod.end = timePeriod.end + intervalToIncrease;
    return timePeriod;
}

const checkAndPublish = (dataArray, dataName) => {
    if (dataArray && dataArray[0]) {
        PubSub.publish(dataName, dataArray[0]);
        dataArray.shift(); 
    }
}

const postVehicles = (timePeriod) => {
    postPollVehicles(updateVehiclesData, timePeriod)
}

const postTimeline = (timePeriod) => {
    postPollTimeline(updateTimelineData, timePeriod)
}

const postIncidents = (timePeriod) => {
    postPollIncidents(updateIncidentsData, timePeriod)
}

const updateVehiclesData = (data) => {
    vehiclesData.push(data);
}

const updateTimelineData = (data) => {
    timelineData.push(data);
}

const updateIncidentsData = (data) => {
    incidentsData.push(data);
}