import React from 'react';
import './UserProfile.css';
import { useGlobal } from "../../../services/globalStatesStore"
import { LOG_OUT, USERNAME, USER_TYPE, CONTROL_ROOM } from '../../../constants/constants';
import { Auth } from "aws-amplify";

export default function UserProfile(props) {
    const [globalState] = useGlobal();

    async function onLogout() {
        console.log("props: ",props);
        onPopupClose();
        await Auth.signOut();
        
        props.appProps.userHasAuthenticated(false);
        props.appProps.history.push("/login");
    }

    function onPopupClose() {
        props.toggleOpen(false);
    }

    return (
        <div className="user-profile-popup">
            <div className="close-button" onClick={props.toggleOpen && onPopupClose} />
            <div className='user-profile-content'>
                <div className="grid-phase">
                    <div className="user-headline">{USERNAME}</div>
                    <div className="user-data-text">{globalState.user.email}</div>
                </div>
                <div className="grid-phase">
                    <div className="user-headline">{USER_TYPE}</div>
                    <div className="user-data-text">{globalState.user.type}</div>
                </div>
                <div className="grid-phase">
                    <div className="user-headline">{CONTROL_ROOM}</div>
                    <div className="user-data-text">{globalState.user.room}</div>
                </div>
            </div>
            <hr />
            <button type="button" className="btn btn-primary btn-lg log-out-button" onClick={props.toggleOpen && onLogout}>{LOG_OUT}</button>
        </div>
    )
}