import Highcharts from 'highcharts';
import PubSub from 'pubsub-js';
import { stopPollingVehiclesAndIncidents, startPollingVehiclesAndIncidents, vehiclesDataName, incidentsDataName } from './../../../../services/pollingService';
import { postPollIncidents, postPollVehicles } from '../../../../services/apiCalls';
import $ from "jquery";
import { initBufferedVehicles } from '../map/Map';

const pollVehiclesAndIncidentsInterval = 2000;
const TwentyFourHoursInMilliseconds = 86400000;
const predictionsAsHours = 3600000 * 2;
const predictionColor ='#7F00FF';
const cursorMarkColor = '#DEE2E6';
const chartPlotLeftMargin = 65;

var cursorLine;
var clickXOnScreen;
var clickYOnScreen;

const colorsDictonary = {
    Low: '#FFC800',
    Medium: '#FF7F00',
    High: '#E11414',
    weather: '#0064FF',
    prediction: '#7F00FF',
}

const getMarkerByStatusFunctionsDictionary = {
    new: getGradientMarkerByColor,
    ongoing: getFillCircleMarkerByColor,
    past: getEmptyCircleMarkerByColor,
};

export function start(e) {

    $(document).bind({
        'mousemove.cursorLine': step,
        'mouseup.cursorLine': stop
    });

    clickXOnScreen = e.pageX - cursorLine.translateX;        
}

var step = function (e) {
    var timeline = $('#timeline');
    var chart = timeline[0].chart;

    if (e.pageX > chartPlotLeftMargin && e.pageX < chartPlotLeftMargin + chart.plotWidth) {
        cursorLine.translate(e.pageX - clickXOnScreen, e.pageY - clickYOnScreen);
    }
}

var stop = function (e) {
    var timeline = $('#timeline');
    var chart = timeline[0].chart;
    
    var newTimestamp = calculateNewDraggedTimestamp(chart, e.pageX);
    setNewCursorPosition(chart, timeline[0], newTimestamp);

    $(document).unbind('.cursorLine');
}

const calculateNewDraggedTimestamp = (chart, pageXPixels) => {
    const onePixelOnScreenInMillis = TwentyFourHoursInMilliseconds / chart.plotWidth;
    var pixelsDragCount = pageXPixels - clickXOnScreen;
    var millisThatDragged = Math.floor(pixelsDragCount * onePixelOnScreenInMillis);                
    return chart.xAxis[0].plotLinesAndBands[0].options.value + millisThatDragged;
}

const setNewCursorPosition = (chart, timelineContainer, newTimestamp) => {
    if (timelineContainer.isToggledToPlay) {
        goToWhilePlaying(newTimestamp);
    } else {
        goToWhilePaused(newTimestamp);
    }
        
    timelineContainer.cursorPosition = newTimestamp ;
    updateChartCursorPosition(chart, newTimestamp);
}

export function goToWhilePlaying(currentTimeAtThisPoint) {
    // initBufferedVehicles();  /// stop buffer on drag and click on timeline point only.
    stopPollingVehiclesAndIncidents();
    startPollingVehiclesAndIncidents({ start: currentTimeAtThisPoint, end: currentTimeAtThisPoint + pollVehiclesAndIncidentsInterval }, pollVehiclesAndIncidentsInterval)
}

export function goToWhilePaused(currentTimeAtThisPoint) {
    postPollVehicles((data) => { publishData(data, vehiclesDataName) }, { start: currentTimeAtThisPoint, end: currentTimeAtThisPoint + 50 });
    postPollIncidents((data) => { publishData(data, incidentsDataName) }, { start: currentTimeAtThisPoint, end: currentTimeAtThisPoint + 50 });
}

const publishData = (data, dataName) => {
    if (data) {
        PubSub.publish(dataName, data);
    }
}

export function updateChartCursorPosition(chart, currentPoint){     
    if (!chart || !chart.xAxis[0] || !chart.xAxis[0].plotLinesAndBands[0]) {
        return;
    }
    chart.xAxis[0].plotLinesAndBands[0].options.value = currentPoint;
    chart.xAxis[0].plotLinesAndBands[0].options.color = cursorMarkColor;
    chart.xAxis[0].update();
    
    cursorLine = initCursorLineDragDropEvents(chart);
}

const initCursorLineDragDropEvents = (chart) => {
    return chart.xAxis[0].plotLinesAndBands[0].svgElem.attr({
    })
        .css({
        'cursor': 'pointer'
    })
        .translate(0, 0)
        .on('mousedown', start);
}

export function updateChartPlotBands(chart, start, end) {

    const pastPlotBands = {
        color:'#2A2E32',                                 
        from: start,
        to: end,
    };
    const predictionPlotBands = {
        color: predictionColor,
        from: end,
        to: end + predictionsAsHours, 
    };

    chart.xAxis[0].options.plotBands[0] = pastPlotBands;
    chart.xAxis[0].options.plotBands[1] = predictionPlotBands;
    chart.xAxis[0].update();
}

export function getEmptyCircleMarkerByColor(color) {
    return {
        symbol: 'circle',
        fillColor: 'transparent',           
        lineWidth: 1,
        lineColor: color,
        radius: 5,
        enabled: true                 
    } 
}

export function getFillCircleMarkerByColor(color) {
    return {
        symbol: 'circle',
        enabled: true,
        fillColor: color,           
        lineWidth: 1,
        lineColor: color,
        radius: 5,
    } 
}

export function getGradientMarkerByColor(color) {
    return {
        symbol: 'circle',
        enabled: true,
        radius: 10,
        fillColor: getRadialGradientByColor(color)
    }
}

export function getRadialGradientByColor(color) {
    return {                        
        radialGradient: {
            cx: 0.5,
            cy: 0.5,
            r: 0.8
        },
        stops: [
            [0, Highcharts.Color(color).setOpacity(1).get('rgba')],
            [0.33, Highcharts.Color(color).setOpacity(1).get('rgba')],
            [0.33, Highcharts.Color(color).setOpacity(0.5).get('rgba')],
            [0.49, Highcharts.Color(color).setOpacity(0.5).get('rgba')],
            [0.49, Highcharts.Color(color).setOpacity(0.33).get('rgba')],
            [1, Highcharts.Color(color).setOpacity(0.33).get('rgba')]
        ]
    };
}

export function getIncidentMarker(priority, status, type) {
    let color;
    if (type === 'black-ice' || type === 'predictions') {
        let parsedType = type === 'black-ice' ? 'weather' : 'prediction';
        color = colorsDictonary[parsedType];
    } else {
        color = colorsDictonary[priority];
    }

    let getMarkerFunc = getMarkerByStatusFunctionsDictionary[status];
    return getMarkerFunc(color);
}

export function setElementMarkerByStatus(element, status) {
    if (status === 'ongoing') {
        element.status = status;
        element.marker.fillColor = getMarkerColor(element.type, element.priority);
        element.marker.lineColor = getMarkerColor(element.type, element.priority);
        element.marker.lineWidth = 1;
        element.marker.radius = 5;
    } else if (status === 'past') {
        element.status = status;
        element.marker.fillColor = 'transparent'
    }
}

function getMarkerColor(type, priority) {
    let color;
    if (type === 'black-ice' || type === 'predictions') {
        let parsedType = type === 'black-ice' ? 'weather' : 'prediction';
        color = colorsDictonary[parsedType];
    } else {
        color = colorsDictonary[priority];
    }
    return color;
}