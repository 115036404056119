import React, { useEffect, useState } from 'react';
import './MainNavigation.css';
import Tabs from './tabs/Tabs';
import Dashboard from '../main-navigation/dashboard/Dashboard';
import packageJson from '../../../package.json';
import Popup from '../common/popup/Popup'
import UserProfile from './user-profile/UserProfile'
import { useGlobal } from "../../services/globalStatesStore"
import { NOT_IMPLEMENTED } from '../../constants/constants';
import { getUserProfile, getConfiguration, postWeather, getLocationFilter } from '../../services/apiCalls';

function MainNavigation(props) {

  const [, globalActions] = useGlobal();
  const [selectedTab, setSelectedTab] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  const tabSelected = selectedTab => event => setSelectedTab(selectedTab);
  const onUserProfile = event => setOpenPopup(!openPopup);

  const mapWidthInPixels = window.innerWidth - 130; // Remember that is you change css style these values should be modified
  const mapHeightInPixels = window.innerHeight - 150 - 160 + 2; // Remember that is you change css style these values should be modified
  const postWeatherInterval = 60000;

  const startPostWeatherInterval = (locationData) => {
    const interval = setInterval(() => {
      postWeather(globalActions.initWeather, { location: locationData.location, time: Date.now()});
    }, postWeatherInterval);
    return () => clearInterval(interval);
  }

  useEffect(() => {    

    getUserProfile((userProfile) => {
      globalActions.initUserProfile(userProfile);
    });
    
    getLocationFilter((locationData) => {
      globalActions.initLocation(locationData);
      postWeather(globalActions.initWeather, {location: locationData.location, time: Date.now()});
      startPostWeatherInterval(locationData);
    });

    (async function () {
      await getConfiguration((callback) => {        
        globalActions.initConfiguration(callback);
        setIsConfigLoaded(true);
      },{ width: mapWidthInPixels, height: mapHeightInPixels });
    })();
  }, [globalActions, mapWidthInPixels, mapHeightInPixels]);

  return props.isAuthenticated && isConfigLoaded && (
    <div className="main-navigation">
      <div className="main-navigation-bar">
        <div className="clickable-navbar-button back" />
        <Tabs action={tabSelected} />
        <div className="logo">
          <label className="version-label c-666A6E">CCC v{packageJson.version}</label>
        </div>
        {process.env.NODE_ENV === 'development' && <div className="dev-warn"> {process.env.NODE_ENV} mode</div>}
        <div className="clickable-navbar-button user-profile-btn" onClick={onUserProfile} />
        {openPopup && <Popup styleName="bottom-right" content={<UserProfile appProps={props} toggleOpen={setOpenPopup} />} />}
      </div>
      <div className="content">
        {selectedTab && selectedTab === 'Dashboard' ? <Dashboard /> : <div>{NOT_IMPLEMENTED}</div>}
      </div>
    </div>
  );
};

export default MainNavigation;