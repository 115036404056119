export const INVALID_DATE_DAY =  "Invalid date value for day: ";
export const INVALID_DATE_MONTH = "Invalid date value for month: ";
export const INVALID_DATE_YEAR = "Invalid date value for year: {0} - must be between 1902 and {1}";
export const DATE_NOT_EXIST = "This date not exist: ";
export const INVALID_DATE_FORMAT = "Invalid date format: ";
export const INVALID_TIME_HOURS = "Invalid time value for hours: ";
export const INVALID_TIME_MINUTES = "Invalid time value for minutes: ";
export const INVALID_TIME_FORMAT = "Invalid time format: ";
export const DATE_ERROR_FLAG = "DATE_ERROR";
export const TIME_ERROR_FLAG = "TIME_ERROR";
export const DATE_OR_TIME_MISSING = "date or time values are missing.";
export const DATE_NOT_HISTORICAL = "This is future date."
export const NOT_IMPLEMENTED = "Not implemented!";

export const LOG_OUT = "LOG OUT";
export const USERNAME = "Username";
export const USER_TYPE = "User Type";
export const CONTROL_ROOM = "Control Room";
export const ROAD = "Road";
export const KM = "Km";
export const DIRECTION = "Direction";

export const PREDICTIONS_FILTER = "Prediction/s";
export const SVD_FILTER = "SVD";
export const ACCIDENTS_FILTER = "Accident/s";
export const UNCATEGORIZED_FILTER = "Uncategorized";
export const WRONG_WAY_FILTER = "Wrong Way";
export const ANIMALS_FILTER = "Animal/s";
export const CONGESTION_FILTER = "Congestion";
export const SEVERE_WEATHER_FILTER = "Severe Weather";
export const CAVS_FILTER = "CAVs";

export const HISTORICAL_FLAG = "Historical";
export const REALTIME_FLAG = "RealTime";
export const SIGN_IN_FALIURE_MESSAGE = "Sorry, we can't find an account with these credentials. Please try again";
