import React, { useEffect, useRef, useState, useCallback } from "react";
import Highcharts from 'highcharts';
import './Timeline.css';
import { useGlobal } from "../../../../services/globalStatesStore";
import { startPollingVehiclesAndIncidentsFromLastInterval, stopPollingTimeline, stopPollingVehiclesAndIncidents, startPollingTimeline, startPollingVehiclesAndIncidents, timelineDataName, } from './../../../../services/pollingService';
import { initBufferedVehicles, setPauseFlag } from "../map/Map";
import * as timelineUtils from './timelineUtils';
import * as dataPolling from './timelineDataPolling';
import IncidentAlertPopup from './../incident-alert-overview/incident-alert-popup/IncidentAlertPopup';
import moment from 'moment';

import Boost from 'highcharts/modules/boost';
Boost(Highcharts);

const realTimeFleg = "RealTime";
const pauseStyleClass = "pause";
const playStyleClass = "play";
const visibleStyleClass = "visible";
const hiddenStyleClass = "hidden";
const plotBandsBackgroundColor = '#2A2E32';
const predictionColor = '#7F00FF';
const cursorLineColor = '#DEE2E6';
const vehicleCountTicksLabelColor = '#6FD5FF';
const speedTicksLabelColor = '#A2A6AA'
const firstLineColor = '#6FD5FF';
const secondLineColor = '#A2A6AA';
const hourAsMilliseconds = 3600000;
const historicalRangeInHours = 24;
const predictionsAsHours = hourAsMilliseconds * 2;
const historicalRangeInMilliseconds = historicalRangeInHours * hourAsMilliseconds;

var isTimePassed = false;
var pollVehiclesAndIncidentsInterval = 2000;
const pollTimelineInterval = 60000;
const lastMinuteBackInMilliseconds = 60000;

function Timeline(props) {
    const timelineContainer = useRef(null);
    const [globalState, globalActions] = useGlobal();
    const [isToggledToPlay, setToggle] = useState(true);
    const [isOpenIncidentAlertPopup, setIsOpenIncidentAlertPopup] = useState(false);
    const [incidentIdToOpen, setIncidentIdToOpen] = useState();
    const updateCursorPositionInterval = 60000;
    const isInitialMount = React.useRef(true);

    const isHistoricalMode = useCallback(() => {
        return globalState.timeFilterRange !== realTimeFleg;
    }, [globalState.timeFilterRange]);

    const togglePlayPause = useCallback(() => {
        setToggle(!isToggledToPlay);
        timelineContainer.current.isToggledToPlay = !isToggledToPlay;
    }, [isToggledToPlay]);

    const getRealTimeDelay = useCallback(() => {
        const isValidrealTimeDelay = globalState.configuration && globalState.configuration.realTimeDelay;
        return isValidrealTimeDelay ? globalState.configuration.realTimeDelay : 0;
    }, [globalState.configuration]);

    useEffect(() => {
        function initCursorPosition() {
            pollVehiclesAndIncidentsInterval = globalState.configuration && globalState.configuration.vehiclesPollInterval;
            const realTimeDelay = getRealTimeDelay();
            updateCursorPosition(isHistoricalMode() ? props.timePeriod.start : props.timePeriod.end - realTimeDelay);
        }

        initCursorPosition();
    }, [globalState.configuration, props.timePeriod.start, props.timePeriod.end, isHistoricalMode, getRealTimeDelay]);

    useEffect(() => {
        const interval = setInterval(() => {
            isTimePassed = true;
        }, updateCursorPositionInterval);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        initBufferedVehicles();
        setToggle(true);
        timelineContainer.current.isToggledToPlay = true;
    }, [isHistoricalMode]);
    
    useEffect(() => {
        function pausePolling() {
            setPauseFlag(!isToggledToPlay);
            stopPollingTimeline();
            stopPollingVehiclesAndIncidents();
        }

        function playPolling() {
            pausePolling();
            if (!isHistoricalMode()) {
                startPollingTimeline({ start: timelineContainer.current.cursorPosition, end: timelineContainer.current.cursorPosition + pollTimelineInterval }, pollTimelineInterval);
            }
            startPollingVehiclesAndIncidents({ start: timelineContainer.current.cursorPosition, end: timelineContainer.current.cursorPosition + pollVehiclesAndIncidentsInterval }, pollVehiclesAndIncidentsInterval);
        }
        isToggledToPlay ? playPolling() : pausePolling();
    }, [props.timePeriod]);

    useEffect(() => {
        function pausePolling() {
            setPauseFlag(!isToggledToPlay);
            stopPollingTimeline();
            stopPollingVehiclesAndIncidents();
        }

        function playPolling() {
            pausePolling();
            if (isHistoricalMode()) {
                startPollingVehiclesAndIncidentsFromLastInterval(pollVehiclesAndIncidentsInterval);
            }
            else {
                startPollingVehiclesAndIncidents({ start: timelineContainer.current.cursorPosition, end: timelineContainer.current.cursorPosition + pollVehiclesAndIncidentsInterval }, pollVehiclesAndIncidentsInterval);
            }
        }
        if (isInitialMount.current) {
            isInitialMount.current = false;
         } else {
            isToggledToPlay ? playPolling() : pausePolling();   
         }
    }, [isToggledToPlay]);

    useEffect(() => {
        let prevTimeStamp;

        function handleCursorAnimation() {
            if (timelineContainer.current.isToggledToPlay && isHistoricalMode()) {
                playAnimation();
            } else {
                pause();
            }
        }

        function playAnimation(timestamp) {

            var timeDelta;
            if (!prevTimeStamp) {
                prevTimeStamp = timestamp;
            }
            var timeDelta = timestamp - prevTimeStamp;
            if (timeDelta >= updateCursorPositionInterval && isTimePassed) {
                isTimePassed = false;
                var chartRef = timelineContainer.current.chart;
                if (chartRef && chartRef.series) {
                    var curTimeStamp = timelineContainer.current.cursorPosition += updateCursorPositionInterval;
                    var pointToUpdate = chartRef.series[0].data.find(point => point.x >= curTimeStamp);
                    shouldStopAtEnd(chartRef.series[0].data, pointToUpdate);

                    if (pointToUpdate) {
                        timelineUtils.updateChartCursorPosition(chartRef, pointToUpdate.x);
                    }

                    prevTimeStamp = timestamp;
                }
            }
            if (timelineContainer.current) {
                timelineContainer.current.playAnimationRef = requestAnimationFrame(playAnimation);
            }
        }

        function pause() {
            cancelAnimationFrame(timelineContainer.current.playAnimationRef);
        };

        function shouldStopAtEnd(chartSeriesData, pointToUpdate) {
            if (!pointToUpdate || chartSeriesData[chartSeriesData.length - 1] === pointToUpdate) {
                togglePlayPause();
            }
        }

        handleCursorAnimation();
    }, [isToggledToPlay, globalState.timeFilterRange, props, isHistoricalMode, togglePlayPause, globalState.configuration]);

    useEffect(() => {
        const realTimeDelay = getRealTimeDelay();

        function buildChartAfterUpdateData() {
            const speedTicks = dataPolling.getSpeedTicksData();
            const vehicleCountTicks = dataPolling.getVehicleCountTicksData();

            const getChartStructure = (timePeriod, isHistoricalMode, updateCursorPosition) => {
                return {
                    chart: {
                        animation: false,
                        backgroundColor: '#02060A',
                        type: 'spline',
                        height: '160px',
                        margin: [48, 65, 48, 65],
                        style: {
                            fontFamily: 'noto-sans-display'
                        },
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    yAxis: [{
                        visible: false,
                        backgroundColor: '#000000',
                        top: 62,
                        title: {
                            text: ''
                        },
                    }, {
                        tickInterval: vehicleCountTicks[1] / 4,
                        tickAmount: 3,
                        gridLineWidth: 0,
                        offset: 20,
                        // min: vehicleCountTicks[0],  // uncomment this to set min/max values from server.
                        // max: vehicleCountTicks[1],
                        title: {
                            text: ''
                        },
                        labels: {
                            style: {
                                color: vehicleCountTicksLabelColor,
                            },
                        },
                    }, {
                        tickInterval: speedTicks[1] / 4,
                        tickAmount: 3,
                        gridLineWidth: 0,
                        offset: -10,
                        // min: speedTicks[0],  // uncomment this to set min/max values from server. 
                        // max: speedTicks[1],
                        title: {
                            text: ''
                        },
                        labels: {
                            style: {
                                color: speedTicksLabelColor,
                            },
                        },
                    }],
                    xAxis: {
                        type: 'datetime',
                        dateTimeLabelFormats: {
                            hour: '%H:%M',
                            day: '%e %b %y'
                        },
                        tickInterval: hourAsMilliseconds,
                        tickLength: 0,
                        ordinal: false,
                        labels: {
                            y: 35,
                            style: {
                                color: '#B6BABE'
                            },
                            formatter: function () {
                                const { startTimezoneData, endTimezoneData, daylightSavingData } = props.timezoneData;
                                // var label = this.axis.defaultLabelFormatter.call(this);
                                var momentDatetimeObject = moment.utc(this.value);
                                if (daylightSavingData.isClockChangesInTimePeriod) {
                                    if (this.value < daylightSavingData.changeMoment) {
                                        momentDatetimeObject = momentDatetimeObject.add(startTimezoneData.offset, 'seconds');
                                    }
                                    else {
                                        momentDatetimeObject = momentDatetimeObject.add(endTimezoneData.offset, 'seconds');
                                    }
                                    return momentDatetimeObject.utc().format("HH:mm");
                                }
                                momentDatetimeObject = momentDatetimeObject.add(startTimezoneData.offset, 'seconds');
                                return momentDatetimeObject.utc().format("HH:mm");
                            }
                        },
                        minPadding: 0,
                        maxPadding: 0,
                        min: isHistoricalMode ? timePeriod.start : undefined,
                        minRange: isHistoricalMode ? historicalRangeInMilliseconds : undefined,
                        tickmarkPlacement: 'on',
                        crosshairs: false,
                        lineWidth: 0,
                        minorGridLineWidth: 0,
                        gridLineColor: '#8E9296',
                        gridLineWidth: 1,
                        plotBands: getPlotBands(timePeriod),
                        opacity: '0.0',
                        plotLines: [{
                            visible: true,
                            width: 1,
                            color: cursorLineColor,
                            value: isHistoricalMode ? timePeriod.start : timePeriod.end,
                            zIndex: 10,
                        }]
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        x: 100,
                        align: 'left',
                        verticalAlign: 'top',
                        itemStyle: {
                            color: '#A2A6AA'
                        },
                        itemHoverStyle: {
                            color: 'gray'
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            turboThreshold: 10000,
                            boostThreshold: 50000,
                            shadow: false,
                            states: {
                                inactive: {
                                    enabled: false
                                },
                                hover: {
                                    enabled: false
                                }
                            },
                            marker: {
                                enabled: false
                            },
                            point: {
                                events: {
                                    click: function () {
                                        clickOnPoint(this, updateCursorPosition);
                                    }
                                }
                            },
                        }
                    },
                    series: getChartSeries(timePeriod)
                }
            }

            var chartObj = Highcharts.chart('timeline', getChartStructure(props.timePeriod, isHistoricalMode(), updateCursorPosition));

            timelineUtils.updateChartCursorPosition(chartObj, timelineContainer.current.cursorPosition);
            timelineContainer.current.chart = chartObj;

            handleSubscribeTimelineData();
        }

        function handleSubscribeTimelineData() {
            if (isHistoricalMode()) {
                dataPolling.unsubscribeTimeline();
            } else {
                dataPolling.subscribeTimeline(timelineContainer.current.chart);
            }
        }

        function clickOnPoint(point, updateCursorPosition) {
            if (!isHistoricalMode() || isClickablePoint(point)) {
                return;
            }
            var currentTimeAtThisPoint = point.x;
            if (timelineContainer.current.isToggledToPlay) {
                timelineUtils.goToWhilePlaying(currentTimeAtThisPoint);
            } else {
                timelineUtils.goToWhilePaused(currentTimeAtThisPoint);
            }

            updateCursorPosition(currentTimeAtThisPoint);
            timelineUtils.updateChartCursorPosition(point.series.chart, currentTimeAtThisPoint);
        }

        function getPlotBands(timePeriod) {
            let realTimeDelay = 0;
            if (!isHistoricalMode()){
                realTimeDelay = getRealTimeDelay();
            }
            let plotBandsArray = [{
                color: plotBandsBackgroundColor,
                from: timePeriod.start - realTimeDelay,
                to: timePeriod.end - realTimeDelay,
            }]

            if (!isHistoricalMode()) {
                plotBandsArray.push({
                    color: predictionColor,
                    from: timePeriod.end - realTimeDelay,
                    to: timePeriod.end + predictionsAsHours - realTimeDelay,
                });
            }
            return plotBandsArray;
        }

        function isClickablePoint(point) {
            const isPointClickable = point.series.options.clickable;
            if (!isPointClickable) {
                return false;
            }
        }

        function getChartSeries(timePeriod) {
            return [{
                yAxis: 1,
                data: dataPolling.getFirstLineData(),
                name: 'Vehicle Counting',
                id: 'firstLine',
                color: firstLineColor,
                clickable: isHistoricalMode(),
                cursor: isHistoricalMode() ? 'pointer' : 'default',
                pointStart: timePeriod.start,
            }, {
                yAxis: 2,
                data: dataPolling.getSecondLineData(),
                name: 'Average Speed',
                id: 'secondLine',
                color: secondLineColor,
                clickable: isHistoricalMode(),
                cursor: isHistoricalMode() ? 'pointer' : 'default',
                pointStart: timePeriod.start,
            }, {
                yAxis: 1,
                data: isHistoricalMode() ? null : dataPolling.getPredictionsFirstLineData(),
                showInLegend: false,
                name: 'Vehicle Counting',
                id: 'firstPredictionsLine',
                color: firstLineColor,
                clickable: false,
                cursor: undefined,
                pointStart: timePeriod.end
            }, {
                yAxis: 2,
                data: isHistoricalMode() ? null : dataPolling.getPredictionsSecondLineData(),
                showInLegend: false,
                name: 'Average Speed',
                id: 'secondPredictionsLine',
                color: secondLineColor,
                clickable: false,
                cursor: undefined,
                pointStart: timePeriod.end
            }, {
                yAxis: 0,
                showInLegend: false,
                name: 'pastIncidents',
                id: 'pastIncidents',
                color: 'transparent',
                clickable: true,
                cursor: 'pointer',
                data: dataPolling.getPastIncidentsData(),
                zIndex: 10,

                isInside: false,
                point: {
                    events: {
                        click: function (event) {
                            openIncidentAlertPopup(this.id, event);
                        }
                    },
                },
            }, {
                yAxis: 0,
                showInLegend: false,
                name: 'predictionsIncidents',
                id: 'predictionsIncidents',
                color: 'transparent',
                clickable: true,
                cursor: 'pointer',
                data: isHistoricalMode() ? null : dataPolling.getPredictionsIncidentsData(),
                point: {
                    events: {
                        click: function (event) {
                            openIncidentAlertPopup(this.id, event);
                        }
                    },
                },
            }];
        }

        function setPollTimePeriod(timePeriod) {
            if (isHistoricalMode()) {
                return timePeriod;
            } else {
                return ({ start: timePeriod.start - realTimeDelay, end: timePeriod.end - realTimeDelay });
            }

        }
        dataPolling.initDataPolling(setPollTimePeriod(props.timePeriod), buildChartAfterUpdateData, realTimeDelay, isHistoricalMode());
    }, [globalActions, globalState.configuration, getRealTimeDelay, props.timePeriod]); //props.timezoneData // props.timePeriod // isHistoricalMode

    function updateCursorPosition(curPoint) {
        timelineContainer.current.cursorPosition = curPoint;
    }

    function setButtonStatusClass() {
        return isToggledToPlay ? pauseStyleClass : playStyleClass;
    }

    function isVisible() {
        return isHistoricalMode() ? visibleStyleClass : hiddenStyleClass;
    }

    function openIncidentAlertPopup(incidentId, eventArgs) {
        setIncidentIdToOpen(incidentId);
        setIsOpenIncidentAlertPopup(true);

        let popupLeftOffset = calculatePopupLeftOffset(eventArgs);
        document.getElementById("incidentTimelineAlertPopup").style.left = `${popupLeftOffset}px`;
    }

    function calculatePopupLeftOffset(event) {
        const screenWidth = event.view.frames.screen.width;
        const mouseClickLocation = event.pageX;
        const popupWith = 200;
        const popupCenterOffset = popupWith / 2;
        const leftBound = 64;
        const rightBound = screenWidth - 64;

        let popupLeftOffset;
        let leftBoundCondition = mouseClickLocation < (leftBound + popupCenterOffset);
        let rightBoundCondition = mouseClickLocation > (rightBound - popupCenterOffset);

        if (leftBoundCondition) {
            popupLeftOffset = leftBound;
        } else if (rightBoundCondition) {
            popupLeftOffset = rightBound - popupWith;
        } else {
            popupLeftOffset = mouseClickLocation - popupCenterOffset;
        }

        return popupLeftOffset;
    }

    return (<div className='timelineStyle'>
        <div id="timeline" ref={el => (timelineContainer.current = el)} />
        <div id="controls" >
            <button className='timeline-button change-timeline-values-button bg-34383C' disabled></button>
            <button className={'timeline-button play-pause-button bg-34383C ' + setButtonStatusClass() + ' ' + isVisible()} onClick={() => { togglePlayPause() }}></button>
        </div>
        <div id='incidentTimelineAlertPopup' className='incident-popup-container'>
            {isOpenIncidentAlertPopup &&
                <IncidentAlertPopup setIsOpenIncidentAlertPopup={setIsOpenIncidentAlertPopup} incidentId={incidentIdToOpen} />
            }
        </div>
    </div>
    );
}

export default Timeline;