import React, { useState, useEffect } from 'react';
import './IncidentAlertOverview.css';
import IncidentAlertCategoryFilter from './incident-alert-category-filter/IncidentAlertCategoryFilter';
import IncidentAlertPriorityFilter from './incident-alert-priority-filter/IncidentAlertPriorityFilter';
import IncidentAlertList from './incident-alert-list/IncidentAlertList';
import { getIncidentAlerts, subscribeIncidents } from './incidentAlertsData';

function IncidentAlertOverview() {
    const [filteredIncidentsList, setFilteredIncidentsList] = useState(getIncidentAlerts(0, [])); 
    const [incidentsList, setIncidentsList] = useState([]);
    const [priority, setPriority] = useState(0);
    const [filteredTypesList, setFilteredTypesList] = useState([]);
    const [typesCountDictionary, setTypesCountDictionary] = useState({});

    useEffect(() => {
        let incidents = getIncidentAlerts(priority, filteredTypesList);  
        setFilteredIncidentsList(incidents);
    }, [filteredTypesList, priority, incidentsList]);

    useEffect(() => {
        subscribeIncidents(setIncidentsList, setTypesCountDictionary);
    }, []);    
    
    return (
        <div>
            <div className="incident-alert-overview">
                <IncidentAlertCategoryFilter updateSelectedTypes = {setFilteredTypesList} incidentsCountDictionary = {typesCountDictionary}/>            
            </div>
            <IncidentAlertPriorityFilter updateSelectedPriority = {setPriority}/>
            <IncidentAlertList incidentAlerts = {filteredIncidentsList} />
        </div>        
    );
}

export default IncidentAlertOverview;