import React, { useState, useEffect, useCallback } from "react";
import { Button, FormGroup, FormControl, FormLabel, FormCheck } from "react-bootstrap";
import { Auth } from "aws-amplify";
import "./Login.css";
import loginIcon from "../../assets/images/login-icon.svg"
import { SIGN_IN_FALIURE_MESSAGE } from '../../constants/constants';
import Cookies from 'universal-cookie';

// to avoid warning
export default function Login(props) {
    const [showError, setShowError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [forgetPasswordNoteVisibilty, setforgetPasswordNoteVisibilty] = useState('hide-note');
    
    const cookies = new Cookies();
    const rememberMeCookieName = 'rememberme';
    const cookieValue = "rm";
    const cookieMaxAgeInSeconds = 10000000;
   
    const isRememberMeCookie = useCallback(() => {
            return cookies.get(rememberMeCookieName) === cookieValue;
        }, [cookies],
    );

    useEffect(() => {
        if (isRememberMeCookie()) {
            setRememberMe(true);
        } else {
            setRememberMe(false);
        }
    },[isRememberMeCookie]);

    

    function getAutoCompleteMode() {
        return isRememberMeCookie() ? "on" : "new-password";
    }

    function saveRememberMeCookie(value) {
        if (value) {
            cookies.set(rememberMeCookieName, cookieValue, { maxAge: cookieMaxAgeInSeconds, path: '/' });
            setRememberMe(true);
        } else {
            cookies.remove(rememberMeCookieName);
            setRememberMe(false);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            await Auth.signIn(email, password);          
            props.userHasAuthenticated(true);
            props.history.push("/main");
        } catch (e) {
            setShowError(true);
            // alert(e.message);
        }
    }

    return (
        <div className="Login">
            {process.env.NODE_ENV === 'development' && <div className="dev-warn"> {process.env.NODE_ENV} mode</div>}
            <form onSubmit={handleSubmit}>
                <FormLabel>Sign In</FormLabel>
                {showError && <FormLabel className="error-message" >{SIGN_IN_FALIURE_MESSAGE}</FormLabel>}
                <FormGroup controlId="email" bssize="large">
                    <FormControl
                        autoFocus
                        type="text"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required={true}
                    />
                    <span className="floating-label">Email</span>
                </FormGroup>
                <FormGroup controlId="real-password" bssize="large">
                    <FormControl
                        type="password"
                        autoComplete={getAutoCompleteMode()}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required={true}
                    />
                    <span className="floating-label">Password</span>
                </FormGroup>
                <Button
                    block
                    bssize="large"
                    type="submit"
                >

                    Sign In
                    <img src={loginIcon} alt=""/>
                </Button>
                <FormGroup controlId="rememberme">
                    <FormCheck
                        type="checkbox"
                        label="Remember me"
                        checked={rememberMe}
                        custom={true}
                        onChange={e => saveRememberMeCookie(e.target.checked)} />
                </FormGroup>                
                <div className='forget-password-button' onClick={() => setforgetPasswordNoteVisibilty('show-note')}>Forgotten Account?</div>
                <div className={` ${forgetPasswordNoteVisibilty}`} >
                    <div className='forget-password-note'>
                        <div className='note-title'>Forgotten Account?</div>
                        We're still working on developing this feature.
                        In the meantime, please contact your system administrator directly to gain access to your account.
                        <div className="close-note-popup" onClick={() => setforgetPasswordNoteVisibilty('hide-note')} />
                    </div>
                </div>
            </form>
        </div>
    );
}